import { apiServices } from '../../_helpers';


export const UserServices = {

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserDetails: () => {
        return apiServices.get('/brand')
            .then((response) => {
                return response;
            })
    },
    
    /**
     * Service for update user details
     * @author Jasin 
     */
     updateUserDetails: (params) => {
        return apiServices.put('/brand/update-user', params)
            .then((response) => {
                return response;
            })
    },

    /**
     * Service for user logout
     * @author Jasin 
     */
    logout: (params) => {
        return true;
    },

    /**
       * Service for Changing Password
       * @author 
    */
    changePassword: (params) => {
    
        return apiServices.put(`/profile/change_password`, { params: params })
            .then((response) => {
                return response;
            })
    }
};
