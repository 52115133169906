import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE
} from "../actions";

const initialState = { 
    user_loading: true, user: {}, user_errors: [], 
    loading: true, user_details: {}, errors: [] 
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Sign in password redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::Sign in password redux */

        
        /** begin::Update user redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                user_details: action.data,
                errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors
            };
        }
        /** end::Update user redux */

        default:
            return state;
    }
}
