import { apiServices, cookieServices } from '../../_helpers';
import ability from '../../_helpers/permissions/ability';


export const SignInServices = {

	/**
	 * Service for singin 
	 *
	 * @parm json params login parameters of {email,password}
	 * @author Akshay 
	 */
	signIn: (params) => {

		return apiServices.post('/check-user-exists', params)
			.then((response) => {
				return response;
			})
	},
	/**
	 * Service for singin 
	 *
	 * @parm json params login parameters of {email,password}
	 * @author Akshay 
	 */
	signInPassword: (params) => {

		return apiServices.post('/login', params)
			.then((response) => {
				let date = new Date();
				date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
				const options = { path: '/', expires: date };
				cookieServices.set('accessToken', response.data.token, options);
				cookieServices.set('permissions', response.data.permissions, options);
				ability();
				return response;
			})
	}

}
