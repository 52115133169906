const validatePermission = (name, value, data) => {
    const errors = {};
    switch (name) {
        case 'permission': {
            errors.permission = '';
            if (value === '')
                errors.permission = 'Enter permission';
            break;
        }default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validatePermission;