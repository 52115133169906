import { makeStyles } from "@mui/styles";
import { DRAWER_WIDTH } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
    drawer: {
        "& .MuiPaper-root": {
            border: `none`,
            "&:hover": {
                '& .menu-name': {
                    display: `block !important`
                },
                '& .MuiBox-root': {
                    height: `100%`
                },
                '& .MuiList-root': {
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    width: `${DRAWER_WIDTH}px !important`,
                    height: `100%`
                }
            }
        },
        "& .MuiListItemIcon-root": {
            minWidth: `25px`
        },
        "& .MuiListItemText-root": {
            marginTop: 0,
            marginBottom: 0
        },
        '& .MuiTypography-root': {
            fontSize: `14px`,
            paddingLeft: `10px`,
            paddingRight: `15px`
        },
        '& .show-menu-name': {
            display: `block !important`
        },
        '& .hide-menu-name': {
            display: `none !important`
        },
        '& .drawer-open': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            width: `${DRAWER_WIDTH}px !important`,
            height: `100%`
        }
    },
    listMenuWrapper: {
        paddingTop: theme.spacing(7)
    },
    menuIcon: {
        fontSize: `22px`
    }

}))

export { useStyles };

