import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const BrandFeeServices = {
    /**
    * function add brand fee
    * @param params
    * @return view
    * @author Jasin
    * @created_at 21/12/2021
    */
    addBrandFee: (params) => {
        return apiServices.post('/brand-fee/add-brand-fee', params)
            .then((response) => {
                return response;
            })
    },

    /**
    * function get brand list for select
    * @param params
    * @return view
    * @author Jasin
    * @created_at 21/12/2021
    */
    getBrandListSelect: (params) => {
        return apiServices.get('/brand-fee/get-brand-list-select', { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
     * function get brand fee list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 22/12/2021
     */
     getBrandFeeList: (params) => {
        return apiServices.get(`/brand-fee/get-brand-fee-list`, { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
     * function get brand fee
     * @param params
     * @return view
     * @author Jasin
     * @created_at 22/12/2021
    */
    getBrandFee: (params) => {
        let queryParam = {};
        queryParam.brand_fee_id = params.id;
        return apiServices.get(`/brand-fee/get-brand-fee?${queryString.stringify(queryParam)}`, params)
            .then((response) => {
                return response;
            }) 
    },

}

