import { EmailLogsServices } from "../services";

export const GET_EMAIL_LOGS_LIST_REQUEST = 'GET_EMAIL_LOGS_LIST_REQUEST';
export const GET_EMAIL_LOGS_LIST_SUCCESS = 'GET_EMAIL_LOGS_LIST_SUCCESS';
export const GET_EMAIL_LOGS_LIST_FAILURE = 'GET_EMAIL_LOGS_LIST_FAILURE';

export const GET_EMAIL_LOGS_DETAILS_REQUEST = 'GET_EMAIL_LOGS_DETAILS_REQUEST'
export const GET_EMAIL_LOGS_DETAILS_SUCCESS = 'GET_EMAIL_LOGS_DETAILS_SUCCESS'
export const GET_EMAIL_LOGS_DETAILS_FAILURE = 'GET_EMAIL_LOGS_DETAILS_FAILURE'

export const EMAIL_LOG_RESEND_MAIL_REQUEST = 'EMAIL_LOG_RESEND_MAIL_REQUEST'
export const EMAIL_LOG_RESEND_MAIL_SUCCESS = 'EMAIL_LOG_RESEND_MAIL_SUCCESS'
export const EMAIL_LOG_RESEND_MAIL_FAILURE = 'EMAIL_LOG_RESEND_MAIL_FAILURE'

export const EmailLogsAction = {


    /**
     * function set get email logs list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 22/04/2022
     */
    getEmailLogsList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return EmailLogsServices.getEmailLogsList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_EMAIL_LOGS_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_EMAIL_LOGS_LIST_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_EMAIL_LOGS_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    getEmailLogsDetails: (params) => {
        return dispatch => {
            dispatch(request(params))
            return EmailLogsServices.getEmailLogsDetails(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_EMAIL_LOGS_DETAILS_REQUEST, request } }
        function success(request, response) { return { type: GET_EMAIL_LOGS_DETAILS_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_EMAIL_LOGS_DETAILS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    emailLogResendMail: (params) => {
        return dispatch => {
            dispatch(request(params))
            return EmailLogsServices.emailLogResendMail(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: EMAIL_LOG_RESEND_MAIL_REQUEST, request } }
        function success(request, response) { return { type: EMAIL_LOG_RESEND_MAIL_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: EMAIL_LOG_RESEND_MAIL_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },
    
}


