import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid, IconButton, MenuItem, Menu, Button, ListItemIcon, TableCell, TableSortLabel} from '@mui/material';
import { MoreHoriz, Send, Visibility } from '@mui/icons-material';

import { DataTable } from '../../_components/DataTable/DataTable';
import { DataTableLoader } from '../../_components/loaders/DataTableLoader'
import { FormInput } from '../../_components/form';
import { makeStyles } from '@mui/styles';
import { EmailLogsAction } from '../../redux/actions';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../routes';
import { SnackBarAlert } from '../../_components/SnackBarAlert/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
        '& .MuiList-root': {
            padding: 6,
            minWidth: 100
        },
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiDivider-root': {
            marginTop: 3,
            marginBottom: 3
        }
    }
}));
function EmailLogsDatatable({ getEmailLogsList, email_logs, email_logs_loading, user, emailLogResendMail, ...props}) {
    const [emailLogsId, setEmailLogsId] = useState('');
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState('');
    const [startSearch, setStartSearch] = useState(false)
    const [page, setPage] = useState(0);
    const [emailLogsList, setEmailLogsList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });
    const [resendMailDisable, setResendMailDisable] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
        let params = {
            search,
            page,
            sort,
            per_page: 10
        }
        getEmailLogsList(params).then((response) => {
            setStartSearch(false)
        });

    }, [startSearch, page, sort])

    useEffect(() => {
        setEmailLogsList(email_logs.email_logs ? email_logs.email_logs : []);
        setTotalCount(email_logs.total)

    }, [email_logs])

    const handleActionClick = (event, value, status) => {
        setAnchorEl(event.currentTarget);
        setEmailLogsId(value);
        setResendMailDisable(status === 'failed' ? false : true)
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    function handleViewClick() {      
        history.push({
            pathname: PostAuthRoutes('email_logs_details').path,
            search: `?logId=${emailLogsId}`
        });

    }

    function handleResendClick() {      
        setResendMailDisable(true);
        setSnackBar({ type: '', message: '' })
        let dispatchAction = emailLogResendMail( { logId: emailLogsId });

        dispatchAction.then((response) => {
            if (response.status !== 1) {
                setSnackBar({type:'error', message:response.message})
            } else {
                setSnackBar({type:'success', message:response.message})
            }
        });

    }

    function changePage(page) {
        setPage(page)
    }

    function handleChange(e) {
        const { name, value } = e;
        setSearch(value);
    }

    function handleStartSearch(e) {
        setPage(0)
        setStartSearch(true)
    }
    
    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (rowIndex, dataIndex) => {
                    return ((dataIndex.tableState.rowsPerPage * dataIndex.tableState.page) + dataIndex.rowIndex + 1) ;
                }
            }
        },
        {
            name: "brand",
            label: "Brand",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "receiver",
            label: "Receiver",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "mail_type",
            label: "Mail Type",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                    <>
                        <span className={"badge " + (value === 'success' ? 'badge-success' : 'badge-danger') } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{(value === 'success' ? 'Success' : 'Failed')}</span> 
                        <br />
                    </>
                    )
                }
            }
        },
        {
            name: "created_at",
            label: "Created on",
            options: {
                // sort: true,
                customBodyRender: value => moment(new Date(value)).format('MM/DD/YYYY'),
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            },

        },
        {
            name: "_id",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, dataIndex) => {
                    var status = emailLogsList[dataIndex.rowIndex].status;

                    return (
                        <>
                            <IconButton
                                aria-label="more"
                                id="action-button"
                                aria-controls="action-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={event => handleActionClick(event, value, status)}
                            >
                                <MoreHoriz />
                            </IconButton>

                            <Menu 
                                id="action-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleActionClose}
                                MenuListProps={{
                                'aria-labelledby': 'action-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    className: classes.menuPaper,
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem 
                                    onClick={handleViewClick}
                                >
                                    <ListItemIcon>
                                        <Visibility fontSize="small" />
                                    </ListItemIcon>
                                    View

                                </MenuItem>
                                <MenuItem 
                                    onClick={handleResendClick}
                                    disabled = {resendMailDisable} 
                                >
                                    <ListItemIcon>
                                        <Send fontSize="small" />
                                    </ListItemIcon>
                                    Resend Mail

                                </MenuItem>
                            </Menu>
                        </>
                    );
                },
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            },

        },
    ];

    const options = {
        serverSide: true,
        filter: false,
        resonsive: 'stacked',
        print: false, //PDF
        search: false,
        download: false, //CSV
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPageOptions: [],
        rowsPerPage: 10,
        page: page,
        count: totalCount,
        sortOrder: {
            name: sort.name,
            direction: sort.direction
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case "changePage":
                    changePage(tableState.page);
                    break;
                    case 'sort':
                    setSort(tableState.sortOrder)
                    break;
            }
        }
    };
    return (
        <div>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <Grid container>
                <Grid item xs={12} md={4} container spacing={2}>
                    <Grid item xs={8}>
                        <FormInput
                            tabIndex={1}
                            label='Search'
                            name='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="outlined" onClick={() => handleStartSearch() } style={{ 'height': '4em' }}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <br />
        {email_logs_loading ? (
            <DataTableLoader />
        ) : (
            <DataTable
                title={"Permissions"}
                data={emailLogsList}
                columns={columns}
                options={options}
            />
        )}
    </div >
    )
}



function mapStateToProps(state) {
    return {
        email_logs: state.EmailLogsReducer.email_logs,
        email_logs_loading: state.EmailLogsReducer.email_logs_loading,
        user: state.UserReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEmailLogsList: (params) => dispatch(EmailLogsAction.getEmailLogsList(params)),
        emailLogResendMail: (params) => dispatch(EmailLogsAction.emailLogResendMail(params)),
    }
};

const connectedEmailLogsDatatable = connect(mapStateToProps, mapDispatchToProps)(EmailLogsDatatable);
export { connectedEmailLogsDatatable as EmailLogsDatatable };

