import { 
    GET_USERS_LIST_REQUEST, GET_USERS_LIST_SUCCESS, GET_USERS_LIST_FAILURE,
    ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE,
    GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE,
    UPDATE_USER_STATUS_REQUEST, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_FAILURE
} from '../actions'

const initialState = { 
    loading: false, request: {}, data: [{}], errors: {}, user: {},
    page: 1, per_page: 18, total: 0, total_pages: 0,
}


export function ManageUsersReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get users list redux 
        case GET_USERS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_USERS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data.users,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
            }
        }
        case GET_USERS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::Get users list redux 

        //  begin::add user redux 
        case ADD_USER_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.data,
            }
        }
        case ADD_USER_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add user redux 

        //  begin::get user redux 
        case GET_USER_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.data,
            }
        }
        case GET_USER_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get user redux 

        //  begin::get user redux 
        case UPDATE_USER_STATUS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case UPDATE_USER_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.data,
            }
        }
        case UPDATE_USER_STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get user redux 
        default:
            return state
    }

}
