import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Helmet } from "react-helmet";

import { GlobalAction, UserAction } from '../../../redux/actions';

import { CircleNotifications, Menu } from '@mui/icons-material';
import { Badge, AppBar, IconButton, InputBase, Toolbar, Typography } from '@mui/material';
import { ShortLogo } from '../../../_components/logo/short-logo';
import { alpha, Box } from '@mui/system';
import { styled } from '@mui/styles';

import { APP_NAME } from '../../../_helpers';
import { getPostComponentNameByPath } from '../../../routes';
import { useStyles, AccountMenu } from '.'
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

function Header({ setDrawer }) {

    const [open, setOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState('Loading')

    const toggleDrawer = () => {
        setOpen(!open);
        setDrawer(!open);
    };

    const classes = useStyles();

    const location = useLocation();
    const appName = useContext(AppNameContext);

    useEffect(() => {
        console.log(location.pathname);
        setPageTitle(getPostComponentNameByPath(location.pathname));
    }, [location])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${pageTitle}`}</title>
            </Helmet>
            <AppBar className={classes.appBar} color="transparent" elevation={0} position="sticky" >
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} >
                        <Menu />
                    </IconButton>
                    <ShortLogo width="70" height="70" className={classes.pageLogo} />
                    <Typography component="div" variant="h6" color="inherit" noWrap className={classes.pageTitle} >
                        {pageTitle}
                    </Typography>
                    {/* <Search>
                        <SearchIconWrapper>
                            <Search />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <IconButton >
                            <Badge badgeContent={4} color="secondary">
                                <CircleNotifications />
                            </Badge>
                        </IconButton> */}
                        <AccountMenu />
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        setDrawer: (params) => dispatch(GlobalAction.setDrawer(params)),
        getUserDetails: dispatch(UserAction.getUserDetails())
    }
};


const connectedHeader = connect(null, mapDispatchToProps)(Header);
export { connectedHeader as Header };

