import React, { useState, useEffect } from 'react'

import { Grid, Button } from '@mui/material'
import { Card, CardContent } from './../../../_components/card'
import { connect } from 'react-redux'
import { FormInput } from './../../../_components/form';
import { UserAction } from '../../../redux/actions';
import validateProfileUpdate from './validateProfileUpdate';
import { validateForm } from './../../../_helpers';

const inputs = {first_name: '', last_name: '', email: '', name:''};

function ProfileUpdate({ user, user_loading, update_errors,  user_details, updateUserDetails, getUserDetails }) {
    const [data, setData] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...inputs });

    useEffect(() => {
        data['first_name'] = user.first_name ?? '';
        data['last_name'] = user.last_name ?? '';
        data['email'] = user.email ?? '';
        setData({ ...data });
    }, [user])

    useEffect(() => {
        getUserDetails();
    }, [user_details])

    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validateProfileUpdate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateProfileUpdate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        data['name'] = data['first_name'] + ' ' + data['last_name'];
        setData({ ...data });
        dispatchUserAction(data);
    }

    const dispatchUserAction = (data) => {
        let dispatchAction = updateUserDetails(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit} noValidate >
                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                tabIndex={1}
                                label='First name'
                                name='first_name'
                                autoFocus={true}
                                onChange={handleChange}
                                value={data.first_name}
                                error={(action.isSubmitted && (errors.first_name)) ? (errors.first_name) : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                tabIndex={2}
                                label='Last name'
                                name='last_name'
                                onChange={handleChange}
                                value={data.last_name}
                                error={(action.isSubmitted && (errors.last_name)) ? (errors.last_name) : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm= {12}>
                            <FormInput
                                tabIndex={3}
                                label='Email'
                                name='email'
                                onChange={handleChange}
                                value={data.email}
                                error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm= {12}>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    )
}

const mapStateToProps = (state) => ({
    user: state.UserReducer.user,
    user_loading: state.UserReducer.user_loading,
    user_details: state.UserReducer.user_details,
    update_errors: state.UserReducer.errors
})

function mapDispatchToProps(dispatch) {
    return {
        updateUserDetails: (params) => dispatch(UserAction.updateUserDetails(params)),
        getUserDetails: (params) => dispatch(UserAction.getUserDetails())
    }
};
const connectedProfileUpdate = connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
export { connectedProfileUpdate as ProfileUpdate };


