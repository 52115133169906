import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    preSignInWrapper: {
        height: `100vh`
    },
    preSignInWrapperItem: {
        border: `1px solid #dadce0`,
        borderRadius: theme.spacing(1)
    },
    preSignInWrapperItemInner: {
        minWidth: `430px`,
        maxWidth: `630px`,
        minHeight:`400px`,
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(6)
    },
    preSigninFooter: {
        flexBasis: `unset !important`
    }
}))

export { useStyles };
