import React, { useState } from 'react'
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../'

function UpdateStatusModal({ show, showLoader, data, closeModal, updateStatus, ...props }) {

    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const onHide = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateStatus(data.id);
    }

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader} size="md">
            <form onSubmit={handleSubmit} noValidate className='ns-form' >
                <ConfzModalHeader id="AddUpdateDeals" title="Update Status" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to ${(data.status === 'active') ? 'inactivate' : 'activate'}  ${data.item}`}?</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                    <ConfzModalFooterSaveBtn tabIndex="3" text="Update" isProcessing={action.isProcessing} />
                </ConfzModalFooter>
            </form>
        </ConfzModal>
    )
}

export { UpdateStatusModal }
