import PropTypes from 'prop-types'
import ShortLogoImage from './gc-logo.svg'

const ShortLogo = ({ alt, width, height, className, style, ...props }) => {
    return (
        <img src={`${ShortLogoImage}`} alt={`${alt}`} width={`${width}px`} height={`${height}px`} className={`${className}`} style={style} {...props} />
    )
}

ShortLogo.defaultProps = {
    alt: "logo",
    width: "100",
    height: "100",
    className: ""
}


ShortLogo.propTypes = {
    props: PropTypes.object,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string
}


export { ShortLogo }
