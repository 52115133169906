import React, { useState } from "react";

import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { maskInput } from "../_helpers";
import { useStyles } from '.'

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    isReadOnly: false,
    maxLength: 255,
    className: '',
    placeholder: '',
};

const FormPassword = ({ name, label, placeholder, type, className, id, value, onChange, isReadOnly, maxLength, maskType, tabIndex, error, info, ...props }) => {

    const classes = useStyles();

    const [values, setValues] = useState({ password: '', showPassword: false });

    const checkKeyPress = (e) => {
        return maskInput(maskType, e);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ name: name, value: value });
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl fullWidth className={classes.formControl} {...props} >
            <InputLabel htmlFor={name} className={classes.label}>{label}</InputLabel>
            <OutlinedInput
                className={classes.input}
                aria-describedby={`helper_${name}`}
                type={values.showPassword ? 'text' : 'password'}
                placeholder={placeholder ? placeholder : label}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyPress={checkKeyPress}
                label={label}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                error={error ? true : false}
                {...props}
            />
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    );
};

FormPassword.defaultProps = defaultProps;

export { FormPassword };
