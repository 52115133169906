import { Alert as MuiAlert , Snackbar } from "@mui/material";
import React, { useState } from "react";

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    type: 'success',
    message: '',
    duration: 6000,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBarAlert = ({ snackBar, type, message, duration, ...props }) => {

      const [open, setOpen] = useState(true);
  
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      return (
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={type} sx={{ minWidth: '300px', width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      );
};

SnackBarAlert.defaultProps = defaultProps;

export { SnackBarAlert };