import { AddBox, CheckBox, CheckBoxOutlineBlank, ChevronRight, ExpandMore, Folder, FolderOpen, IndeterminateCheckBox, InsertDriveFile } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RoleAction } from '../../../redux/actions';
import { PostAuthRoutes } from '../../../routes';
import { SnackBarAlert } from '../../../_components/SnackBarAlert/SnackBarAlert';
import { APP_NAME } from '../../../_helpers';
import { useHistory } from 'react-router';

/**
  * 
  * @author Jasin 
  */
function AssignPermission({ assignPermission, getAssignedPermissions, ...props }) {

    const [roleId, setRoleId] = useState(props.match.params.id ?? '');
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });
    const [roleName, setRoleName] = useState('');

    let history = useHistory();

    function getNodeIds(data) {
        let ids = [];
      
        data?.forEach(({ value, children }) => {
            ids = [...ids, value, ...getNodeIds(children)];
        });
      
        return ids;
    }

    useEffect(() => {
        getAssignedPermissions({show_checkbox : true, role_id: roleId}).then((response) => {
            setData(response.data.permissionArray ?? []);
            setExpanded(getNodeIds(response.data.permissionArray ?? []));
            setChecked((response.data.roleArray && response.data.roleArray.permissions ? response.data.roleArray.permissions : []));
            setRoleName(response.data.roleArray && response.data.roleArray.name ? response.data.roleArray.name : '');
        });
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        dispatchAssignPermissionAction(checked);
    }

    const dispatchAssignPermissionAction = (checked) => {
        setSnackBar({ type: '', message: '' })
        let dispatchAction = assignPermission({permissions: checked, role_id: roleId});
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setSnackBar({type:'success', message:response.message})
            } else {
                setAction({ ...action, isSubmitted: true, isProcessing: false });
                setSnackBar({type:'error', message:response.message})
            }
        });
    }

    const cancelClick = (e) => {
        history.push('/roles');
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('assign_permission').name}`}</title>
            </Helmet>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <form onSubmit={handleSubmit} noValidate >
                    <Card sx={{ height: "100%", padding: "20px"}}>
                        <CardHeader
                            title="Assign Permissions"
                            subheader={roleName}
                        />
                        <CardContent sx={{ lineHeight: '2' }}>
                            { (data.length > 0) && <CheckboxTree
                                nodes={data}
                                checked={checked}
                                expanded={expanded}
                                onCheck={ (checked) => setChecked(checked) }
                                onExpand={ (expanded) => setExpanded(expanded) }

                                icons={{
                                    check: <CheckBox fontSize="inherit" />,
                                    uncheck: <CheckBoxOutlineBlank fontSize="inherit" />,
                                    halfCheck: <CheckBox fontSize="inherit"/>,
                                    expandClose: <ChevronRight fontSize="inherit" />,
                                    expandOpen: <ExpandMore fontSize="inherit" />,
                                    expandAll: <AddBox fontSize="inherit" />,
                                    collapseAll: <IndeterminateCheckBox fontSize="inherit" />,
                                    parentClose: <Folder fontSize="inherit" />,
                                    parentOpen: <FolderOpen fontSize="inherit" />,
                                    leaf: <InsertDriveFile fontSize="inherit" />
                                }}
                            /> } 
                            { (data.length === 0) && 'Sorry, no permissions found'}
                        </CardContent>
                        { (data.length > 0) && <CardActions sx={{ padding: "20px"}}>
                            <Button type="button" variant="contained" color="secondary" onClick={cancelClick}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </CardActions> }
                    </Card>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAssignedPermissions : (params) => dispatch(RoleAction.getAssignedPermissions(params)),
        assignPermission : (params) => dispatch(RoleAction.assignPermission(params)),
    }
};

const connectedAssignPermission = connect(mapStateToProps, mapDispatchToProps)(AssignPermission);
export { connectedAssignPermission as AssignPermission };
