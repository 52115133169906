import { 
    ADD_BRAND_FEE_REQUEST, ADD_BRAND_FEE_SUCCESS, ADD_BRAND_FEE_FAILURE,
    GET_BRAND_LIST_SELECT_REQUEST, GET_BRAND_LIST_SELECT_SUCCESS, GET_BRAND_LIST_SELECT_FAILURE,
    GET_BRAND_FEE_LIST_REQUEST, GET_BRAND_FEE_LIST_SUCCESS, GET_BRAND_FEE_LIST_FAILURE,
    GET_BRAND_FEE_REQUEST, GET_BRAND_FEE_SUCCESS, GET_BRAND_FEE_FAILURE
} from '../actions'

const initialState = { 
    loading: false, request: {}, data: [{}], errors: {}, brandFee: {}, brandListSelect: [{}], totalCount: 0
    // page: 1, per_page: 18, total: 0, total_pages: 0,
}


export function BrandFeeReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::add brand fee redux 
        case ADD_BRAND_FEE_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case ADD_BRAND_FEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                brandFee: action.data,
            }
        }
        case ADD_BRAND_FEE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add brand fee redux 

        //  begin::get brand list for select redux
        case GET_BRAND_LIST_SELECT_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_BRAND_LIST_SELECT_SUCCESS: {
            return {
                ...state,
                loading: false,
                brandListSelect: action.data,
            }
        }
        case GET_BRAND_LIST_SELECT_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get brand list for select redux

        //  begin::Get brand fee list redux 
        case GET_BRAND_FEE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_BRAND_FEE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data.brandFee,
                totalCount: action.totalCount
            }
        }
        case GET_BRAND_FEE_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::Get brand fee list redux 

        

        //  begin::get brand fee redux 
        case GET_BRAND_FEE_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_BRAND_FEE_SUCCESS: {
            return {
                ...state,
                loading: false,
                brandFee: action.data,
            }
        }
        case GET_BRAND_FEE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get brand fee redux 
        default:
            return state
    }

}
