import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    appName: {
        color: theme.palette.primary.main
    },
    preSignInWrapperHeader: {
        marginBottom: theme.spacing(4)
    },
    preSignInWrapperHeaderLogo: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        marginBottom: theme.spacing(1)
    }
}))

export { useStyles };
