import { BusinessServices } from "../services"


export const GET_BUSINESS_LIST_REQUEST = 'GET_BUSINESS_LIST_REQUEST'
export const GET_BUSINESS_LIST_SUCCESS = 'GET_BUSINESS_LIST_SUCCESS'
export const GET_BUSINESS_LIST_FAILURE = 'GET_BUSINESS_LIST_FAILURE'

export const GET_BUSINESS_DETAILS_REQUEST = 'GET_BUSINESS_DETAILS_REQUEST'
export const GET_BUSINESS_DETAILS_SUCCESS = 'GET_BUSINESS_DETAILS_SUCCESS'
export const GET_BUSINESS_DETAILS_FAILURE = 'GET_BUSINESS_DETAILS_FAILURE'

export const GET_BUSINESS_DAILY_COUPONS_REQUEST = 'GET_BUSINESS_DAILY_COUPONS_REQUEST'
export const GET_BUSINESS_DAILY_COUPONS_SUCCESS = 'GET_BUSINESS_DAILY_COUPONS_SUCCESS'
export const GET_BUSINESS_DAILY_COUPONS_FAILURE = 'GET_BUSINESS_DAILY_COUPONS_FAILURE'

export const GET_BUSINESS_COUPON_COUNTS_REQUEST = 'GET_BUSINESS_COUPON_COUNTS_REQUEST'
export const GET_BUSINESS_COUPON_COUNTS_SUCCESS = 'GET_BUSINESS_COUPON_COUNTS_SUCCESS'
export const GET_BUSINESS_COUPON_COUNTS_FAILURE = 'GET_BUSINESS_COUPON_COUNTS_FAILURE'

export const BRAND_LIST_SELECT_REQUEST = 'BRAND_LIST_SELECT_REQUEST';
export const BRAND_LIST_SELECT_SUCCESS = 'BRAND_LIST_SELECT_SUCCESS';
export const BRAND_LIST_SELECT_FAILURE = 'BRAND_LIST_SELECT_FAILURE';

export const BusinessAction = {
    /**
        * Action for sign in 
        * @author Ashwanth
        */

    getBusinessList: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BusinessServices.getBusinessList(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BUSINESS_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_BUSINESS_LIST_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BUSINESS_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    getBusinessDetails: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BusinessServices.getBusinessDetails(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BUSINESS_DETAILS_REQUEST, request } }
        function success(request, response) { return { type: GET_BUSINESS_DETAILS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BUSINESS_DETAILS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    getDailyCouponsIssued: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BusinessServices.getDailyCouponsIssued(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BUSINESS_DAILY_COUPONS_REQUEST, request } }
        function success(request, response) { return { type: GET_BUSINESS_DAILY_COUPONS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BUSINESS_DAILY_COUPONS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    getDailyCouponCounts: (params = null) => {
        return dispatch => {
            dispatch(request(params))
            return BusinessServices.getDailyCouponCounts(params)
                .then(
                    response => {
                        return dispatch(success(params, response));
                    },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BUSINESS_COUPON_COUNTS_REQUEST, request } }
        function success(request, response) { return { type: GET_BUSINESS_COUPON_COUNTS_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BUSINESS_COUPON_COUNTS_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
    * Action for brand select list
    * @author Jasin 
    */
    getBrandListSelect: () => {
        return dispatch => {
            dispatch(request());
            return BusinessServices.getBrandListSelect()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: BRAND_LIST_SELECT_REQUEST } }
        function success(response) { return { type: BRAND_LIST_SELECT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: BRAND_LIST_SELECT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

}