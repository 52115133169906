import React from 'react'
import { connect } from 'react-redux'

import { Grid, Typography, Button } from '@mui/material'
import { Card, CardContent } from './../../../_components/card'
import { Box } from '@mui/system'

function AccountStatus({ user }) {



    let returnRender;
    if (user.status === 'primary') {
        returnRender = (
            <Box>
                <Typography variant="h6" color="primary" >Primary registration completed</Typography>
                <Typography variant="body2" color="initial">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero odio soluta inventore quibusdam distinctio aspernatur pariatur sunt? Iste nobis voluptates velit est harum ex eum, quisquam sapiente perspiciatis optio non!</Typography>
            </Box>
        )
    }


    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {returnRender}
                        <Typography variant="h6" color="error" mt={3}>Deleting your account</Typography>
                        <Typography variant="body2" color="initial" mb={1}>This is permanent.When you delete your account, you won't be able to retrieve the content or information you've shared.If you want to delete your account please click on the below button.</Typography>
                        <Button variant="contained" color="error" > Delete Account </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const mapStateToProps = (state) => ({
    user: state.UserReducer.user,
})

const connectedAccountStatus = connect(mapStateToProps, null)(AccountStatus);
export { connectedAccountStatus as AccountStatus };
