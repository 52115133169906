import { Ability, AbilityBuilder } from "@casl/ability"
import { cookieServices } from "..";

function subjectName(item) {
    return 'all';
}

const ability = () => {
    const permissionSets = new Ability([], { subjectName });
    const permission = cookieServices.get('permissions') ? cookieServices.get('permissions') : [];
    permissionSets.update(defineRulesFor(permission))

    return permissionSets;
}

function defineRulesFor(permission) {
    const { can, rules } = new AbilityBuilder(Ability);

    for(var i = 0; i < permission.length; i++) {
        can(permission[i], 'all')
    }
    return rules;
}

export default ability;