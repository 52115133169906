import React from 'react'
import './Dashboard.css'
import { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
//import Axios from 'axios';
import { DashboardServices } from '../../redux/services/DashboardService'

function Dashboard() {

    const [BusinessCount, setBusinessCount] = useState(0);
    const [DealCount, setDealCount] = useState(0);
    const [ShopperCount, setShopperCount] = useState(0);

    let history = useHistory();

    useEffect(() => {
        loadBusiness();
        loadDeals(); 
        loadShopper();
    }, [ ]);

 
     const loadBusiness = async () => {
      DashboardServices.getBusinessesList()
        .then((res) => {
        setBusinessCount(res.data)
        })
        .catch(()=>{
            console.log('ERR');
        });
     }


     const loadDeals = async () => {
        DashboardServices.getDealsList()
        .then((res) => {
            setDealCount(res.data)

        })
        .catch(()=>{
            console.log('ERR');
        });
     }


     const loadShopper = async () => {
        DashboardServices.getShopperList()
        .then((res) => {
            setShopperCount(res.data)
        })
        .catch(()=>{
            console.log('ERR');
        });
     }

     const redirectBrand = async () => {
        history.push("/business");
     }

     const redirectDeals = async () => {
        history.push("/deal");
    }
     

    return (
        <div>
            <div className="cardsContainer">
                <div className='brandCard'>
                    <h1>{BusinessCount}</h1>
                    <button onClick={redirectBrand}>Brands</button>
                </div>
                <div className='dealsCard'>
                    <h1>{DealCount}</h1>
                    <button onClick={redirectDeals}>Deals</button>
                </div>
                <div className='profilesCard'>
                    <h1>{ShopperCount}</h1>
                    <button >Shoppers</button>
                </div>
            </div>
        </div>

    )
}

export { Dashboard }
