import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from "../actions";

const initialState = { request: {}, errors: {} };

export function RegisterReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign Up redux */
		case REGISTER_REQUEST: {
			return {
				loading: true,
				request: action.request,
			};
		}
		case REGISTER_SUCCESS: {
			return {
				loading: false
			};
		}
		case REGISTER_FAILURE: {
			return {
				loading: false,
				errors: action.errors
			};
		}
		/** end::Sign Up redux */


		default:
			return state;
	}
}
