import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { FormInput } from '../../../_components/form';
import { validateForm } from '../../../_helpers';
import { RoleAction } from '../../../redux/actions';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { SnackBarAlert } from '../../../_components/SnackBarAlert/SnackBarAlert';
import validateRole from './validateRole';

const inputs = { role_id:'', role: ''};

function AddUpdateRole({ id, show, closeModal, addRole, updateRole, getRole}) {
    const [data, setData] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getRole({'id': id}).then((response) => {
                if (response.status === 1) {
                    setData({
                        role_id: id,
                        role: response.data.name,
                    });
                }
                setShowLoader(false);
            });
        } else {
            setData({ ...inputs });
        }

    }, [id])

    const onHide = () => {
        setData({ ...inputs })
        setErrors({ ...inputs })
        closeModal(false);
    }

    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validateRole(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateRole(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchRoleAction(data);
    }

    const dispatchRoleAction = (data) => {
        setSnackBar({ type: '', message: '' })
        let dispatchAction = id ? updateRole(data) : addRole(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setData({ ...inputs })
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                setSnackBar({type:'success', message:response.message})
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
        <React.Fragment>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <ConfzModal isModalOpen={show} onHide={onHide}  size="md" loader={showLoader}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="AddUpdateRole" title={(id) ? 'Update Role' : 'Add Role'} onHide={onHide} />
                    <ConfzModalBody>
                    {!showLoader &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Role'
                                    name='role'
                                    onChange={handleChange}
                                    value={data.role}
                                    error={(action.isSubmitted && (errors.role)) ? (errors.role) : ''}
                                />
                            </Grid>
                        </Grid>
                    }
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn onHide={onHide} />
                        <ConfzModalFooterSaveBtn />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addRole: (params) => dispatch(RoleAction.addRole(params)),
        updateRole: (params) => dispatch(RoleAction.updateRole(params)),
        getRole: (params) => dispatch(RoleAction.getRole(params))
    }
};

const connectedAddUpdateRole = connect(mapStateToProps, mapDispatchToProps)(AddUpdateRole);
export { connectedAddUpdateRole as AddUpdateRole };

