import React, { useEffect, useState } from 'react'
import { DataTable } from '../../_components/DataTable/DataTable';
import { BusinessAction, InvoiceAction } from '../../redux/actions'
import { connect } from 'react-redux';
import { DataTableLoader } from '../../_components/loaders/DataTableLoader';
import { Button, Grid, TableCell, TableSortLabel } from '@mui/material';
import { FormInput, FormSelect } from '../../_components/form';
import { FormDatepicker } from '../../_components/form/form-datepicker/FormDatepicker';

/**
  * 
  * @author Jasin 
  */
function Invoice({ getInvoiceList, loading, getBrandListSelect, brandListSelect, brandListSelectloading }) {
    const [invoiceList, setInvoiceList] = useState([]);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState('');
    const [startSearch, setStartSearch] = useState(false);
    const [filter, setFilter] = useState({ brand_filter : '', date_filter : ''});
    const [startFilter, setStartFilter] = useState(false);
    const [clearFilter, setclearFilter] = useState(false);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    
    useEffect(() => {
        let params = {
            search,
            page,
            filter,
            sort
        }
        getInvoiceList(params).then((response) => {
            setInvoiceList(response.data && response.data.length>0 ? response.data : []);
            setTotalCount(response.totalCount)
            setStartSearch(false)
            setStartFilter(false)
            setclearFilter(false)
        });

    }, [startSearch, page, startFilter, sort])
    
    function changePage(page) {
        setPage(page)
    }

    function handleChange(e) {
        const { name, value } = e;
        setSearch(value);
    }

    function handleStartSearch(e) {
        setPage(0)
        setStartSearch(true)
    }

    function handleFilterChange(e) {
        const { name, value } = e;
        filter[name] = value;
        setFilter({ ...filter });
    }

    function handleStartFilter(e) {
        setPage(0)
        setStartFilter(true)
    }

    function handleFilterReset(e) {
        setFilter({ brand_filter : '', date_filter : ''});
        setclearFilter(true)
        setPage(0)
        setStartFilter(true)
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (rowIndex, dataIndex) => {
                    return ((dataIndex.tableState.rowsPerPage * dataIndex.tableState.page) + dataIndex.rowIndex + 1) ;
                }
            }
        },
        {
            name: "brand_name",
            label: "Brand",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "invoice_number",
            label: "Statement Number",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "created_at",
            label: "Statement Date",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            }
        },
        {
            name: "invoice_start_date",
            label: "Coupons Date Range",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' }),
                customBodyRender: (value, dataIndex) => {
                    return (value + '-' + invoiceList[dataIndex.rowIndex].invoice_end_date);
                }
            }
        },
        {
            name: "total_coupons",
            label: "Number Of Coupons",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            }
        },
        {
            name: "total_amount",
            label: "Amount ($)",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' }),
                customBodyRender: (value, dataIndex) => {
                    return ('$' + value);
                }
            }
        },
        {
            name: "status",
            label: "Payment Status",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' }),
                customBodyRender: (value) => {                    
                    return (
                        <>
                            <span className={"badge " + (value === 'Success' ? 'badge-success' : (value === 'Pending' ? 'badge-warning' : 'badge-danger')) } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{value}</span> 
                            <br />
                        </>
                    );
                },
            },
        },
        {
            name: "created_at",
            label: "Paid On",
            options: {
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            },
        },
    ];

    const options = {
        serverSide: true,
        filter: false,
        resonsive: 'stacked',
        print: false, //PDF
        search: false,
        download: false, //CSV
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPageOptions: [],
        rowsPerPage: 10,
        page: page,
        count: totalCount,
        sortOrder: {
            name: sort.name,
            direction: sort.direction
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case "changePage":
                    changePage(tableState.page);
                    break;
                case 'sort':
                    setSort(tableState.sortOrder)
                    break;
            }
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={8} container spacing={1} >
                    <Grid item xs={12} md={8} container spacing={2}>
                        <Grid item xs={6}>
                            <FormSelect
                                tabIndex={1}
                                label='Brand'
                                name='brand_filter'
                                onChange={handleFilterChange}
                                value= {{value: filter.brand_filter, name: ""}}
                                clearFilter={clearFilter}
                                options = {!brandListSelectloading ? brandListSelect : []}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormDatepicker 
                                label='Date'
                                name='date_filter'
                                value={filter.date_filter}
                                onChange={handleFilterChange}
                                clearFilter={clearFilter}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container spacing={1}>
                        <Grid item xs={6} md={4}>
                            <Button fullWidth variant="outlined" onClick={() => handleStartFilter() } style={{ 'height': '4em' }}>
                                    Filter
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Button fullWidth variant="contained" onClick={() => handleFilterReset() } style={{ 'height': '4em' }}>
                                    Reset
                            </Button>
                        </Grid>                 
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} container spacing={2}>
                    <Grid item xs={8}>
                        <FormInput
                            tabIndex={1}
                            label='Search'
                            name='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button fullWidth variant="outlined" onClick={() => handleStartSearch() } style={{ 'height': '4em' }}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <br />
            <br />

            {loading ? (
                <DataTableLoader />
            ) : (
                <DataTable
                title={"Invoice"}
                data={invoiceList}
                columns={columns}
                options={options}
            />
            )}
        </div >
    )
}

function mapStateToProps(state) {
    return {
        loading: state.InvoiceReducer.loading,
        brandListSelect: state.BusinessReducer.brandListSelect,
        brandListSelectloading: state.BusinessReducer.brandListSelectloading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBrandListSelect: dispatch(BusinessAction.getBrandListSelect()),
        getInvoiceList: (params) => dispatch(InvoiceAction.getInvoiceList(params))
    }
};

const connectedInvoice = connect(mapStateToProps, mapDispatchToProps)(Invoice);
export { connectedInvoice as Invoice };