import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const RoleServices = {
    /**
    * function add roles
    * @param params
    * @return view
    * @author Jasin
    * @created_at 20/01/2022
    */
    addRole: (params) => {
        return apiServices.post('/role/add-role', params)
            .then((response) => {
                return response;
            })
    },

    /**
    * function update roles
    * @param params
    * @return view
    * @author Jasin
    * @created_at 20/01/2022
    */
    updateRole: (params) => {
        return apiServices.post('/role/update-role', params)
            .then((response) => {
                return response;
            })
    },

    /**
     * function get role
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
    */
    getRole: (params) => {
        let queryParam = {};
        queryParam.role_id = params.id;
        return apiServices.get(`/role/get-role?${queryString.stringify(queryParam)}`, params)
            .then((response) => {
                return response;
            }) 
    },

    /**
     * function get role
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
     */
    getRoleList: (params) => {
        return apiServices.get(`/role/get-role-list`, { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
    * function assign permission
    * @param params
    * @return view
    * @author Jasin
    * @created_at 24/01/2022
    */
    assignPermission: (params) => {
        return apiServices.post('/role/assign-permission', params)
            .then((response) => {
                return response;
            })
    },

    /**
     * function get assigned permissions
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    getAssignedPermissions: (params) => {
        return apiServices.get(`/role/get-assigned-permissions`, { params: params })
            .then((response) => {
                return response;
            })
    },
    
    /**
     * function update role status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    updateRoleStatus: (params) => {
        return apiServices.put('/role/update-role-status', params)
            .then((response) => {
                return response;
            })
    },

    /**
     * function get permission list for select
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
     */
    getRoleListSelect: () => {
        return apiServices.get(`/role/get-role-list-select`)
            .then((response) => {
                return response;
            })
    },
    
}

