import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useStyles } from '.'

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    isReadOnly: false,
    maxLength: 255,
    className: '',
    placeholder: '',
    options: [],
    clearFilter: false
};

const FormSelect = ({ name, label, placeholder, type, className, id, value, onChange, isReadOnly, maxLength, maskType, tabIndex, error, info, options, clearFilter, ...props }) => {

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState((value && value.name && value.value) ? {name: value.name, value: value.value} : null);

    function handleSelectChange(event, newValue) {
        setSelected((newValue && newValue.name && newValue.value) ? { name: newValue.name, value: newValue.value } : null)
        onChange({ 
            'name': name,
            'value': newValue ? newValue.value : '',
            'type': 'select' 
        })
    }
    
    useEffect(() => {
        if(clearFilter) {
            setSelected(null)
        }
    }, [clearFilter])

    const classes = useStyles();
    return (
        <FormControl fullWidth>
            <Autocomplete
                disablePortal
                name={name}
                value={selected}
                autoselect ={ true }
                isOptionEqualToValue={(option, newValue) => {
                    if(newValue.value === "") { 
                        return true; 
                    } else if(option.value === newValue.value){
                        return true; 
                    }
                }}
                options={options}
                getOptionLabel={(option) => (option && option.name ? option.name : '')} 
                renderInput={(params) => (<TextField {...params} error={error ? true : false} label={label} InputProps={{...params.InputProps,readOnly: isReadOnly}}/>)}
                open={ open }
                onOpen={ () => !isReadOnly && setOpen(true) }
                onClose={ () => setOpen(false) }
                disableClearable={ isReadOnly }
                onChange={handleSelectChange}
            />
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    );
};

FormSelect.defaultProps = defaultProps;

export { FormSelect };
