const validateUser = (name, value, data) => {
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'first_name': {
            errors.first_name = '';
            if (value === '')
                errors.first_name = 'Enter first name';
            break;
        } case 'last_name': {
            errors.last_name = '';
            if (value === '')
                errors.last_name = 'Enter last name';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
            if (value === '')
                errors.email = 'Enter an email address';
            break;
        } case 'password': {
            const confirmPassword = data.confirm_password ?? '';
            errors.password = value.length < 6 ? 'Password must be 6 characters long' : '';
            if (confirmPassword !== '' && confirmPassword === value)
                errors.confirm_password = '';
            if (value === '')
                errors.password = 'Enter password';
            break;
        } case 'role_id': {
            errors.role_id = '';
            if (value === '')
                errors.role_id = 'Select role';
            break;
        }default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateUser;