import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { getComponentNameByPath, PreAuthMainComponents, PrivateRoute } from './routes';
import { NotFound } from './app/not-found';
import './App.scss'
import { GlobalAction } from './redux/actions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { AppNameContext } from './_contexts';
import { PageLoader } from './_layouts/loader/page-loader/PageLoader';
import { InitialLoader } from './_layouts/loader/initial-loader/InitialLoader';
import { Toast } from './_layouts/toast';
import { useEffect } from 'react';
import { PostSignIn } from './_layouts/post-signin';
import { APP_NAME } from './_helpers';

function App({ toast, pageLoader, initialLoader, hideInitialLoader }) {

  const menuRoutes = Object.values(PreAuthMainComponents());


  useEffect(() => {
    hideInitialLoader();
  }, [hideInitialLoader])


  return (
    <AppNameContext.Provider value={APP_NAME}>
      <HelmetProvider>
        <Switch>
          {menuRoutes.map((route, index) => (
            <Route exact key={index} path={route.path} component={route.component} />
          ))}
          <PrivateRoute path="/" component={PostSignIn}></PrivateRoute>
          <Route component={NotFound} />
        </Switch>
        <PageLoader loader={pageLoader} />
        <InitialLoader loader={initialLoader} />
        <Toast message={toast} />
      </HelmetProvider>
    </AppNameContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    pageLoader: state.GlobalReducer.pageLoader,
    initialLoader: state.GlobalReducer.initialLoader,
    toast: state.GlobalReducer.toastMessage,
  };
}

function mapDispatchToProps(dispatch) {

  return {
    hideInitialLoader: () => dispatch(GlobalAction.hideInitialLoader())
  }
};



const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
