import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const ManageUsersServices = {

    /**
     * function get users list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
     */
    getUsersList: (params) => {
        let queryParam = {};
        queryParam.page = params.page;
        if (params.search) queryParam.search = params.search; else delete queryParam.search;
        if (params.sortOrder['name']) queryParam.sortName = params.sortOrder['name']; else  queryParam.sortName ='_id';
        if (params.sortOrder['direction']) queryParam.sortDirection = params.sortOrder['direction']; else queryParam.sortDirection = -1;
        return apiServices.get(`/users/get-users?${queryString.stringify(queryParam)}`, params)
            .then((response) => {
                return response;
            }) 
    },

    /**
     * function add user
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
     */
    addUser: (params) => {
        return apiServices.post('/users/add-user', params)
            .then((response) => {
                return response;
            })
    },

    /**
     * function get user
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
    */
    getUser: (params) => {
        let queryParam = {};
        queryParam.user_id = params.id;
        return apiServices.get(`/users/get-user?${queryString.stringify(queryParam)}`, params)
            .then((response) => {
                return response;
            }) 
    },

    /**
     * function update user status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 30/12/2021
    */
    updateStatus: (params) => {
        return apiServices.put('/users/update-user-status', params)
            .then((response) => {
                return response;
            })
    },
    

}

