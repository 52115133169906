import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { AddUpdateRole, RoleDatatable } from '.';
import { UpdateStatusModal } from '../../../_components/modal';
import { RoleAction } from '../../../redux/actions';
import { SnackBarAlert } from '../../../_components/SnackBarAlert/SnackBarAlert';
import ability from '../../../_helpers/permissions/ability';
import { Can } from '../../../_helpers';

const useStyles = makeStyles((theme) => ({
    addRoleButton: {
        "float" : "right"
    }
}));

/**
  * 
  * @author Jasin 
  */
function Role({updateRoleStatus}) {

    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [roleId, setRoleId] = useState('');
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });
    const classes = useStyles();

    const addUpdateRoleModal = (id='') => {
        setRoleId(id ?? '');
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    const statusUpdateModal = ({id, status, item}) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    const updateStatusAction = (id) => {
        setSnackBar({ type: '', message: '' })
        setShowStatusUpdateModal(!showStatusUpdateModal);
        updateRoleStatus({ 'id': id }).then((response) => {
            if (response.status === 1) {
                setSnackBar({type:'success', message:response.message})
            } else {
                setSnackBar({type:'error', message:response.message})
            }
        });
    }

    return (
        <div>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Can I='add_update_roles' ability= { ability() }>
                        <Button variant="outlined" startIcon={<AddIcon />} className={classes.addRoleButton} onClick={() => addUpdateRoleModal()}>
                            Add Role
                        </Button>
                    </Can>

                    <AddUpdateRole show={showAddUpdateModal} closeModal={addUpdateRoleModal} id={roleId}/>
                    <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction}  />
                </Grid>
                <Grid item xs={12}>
                    <RoleDatatable addUpdateRoleModal = {addUpdateRoleModal} statusUpdateModal = {statusUpdateModal}/>
                </Grid>
            </Grid>
        </div >
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateRoleStatus: (params) => dispatch(RoleAction.updateRoleStatus(params))
    }
};

const connectedRole = (connect(mapStateToProps, mapDispatchToProps)(Role));
export { connectedRole as Role };