import React from 'react'
import PropTypes from 'prop-types'

function ConfzModalBody({ className, children }) {
    return (
        <div className={`modal-body ${className}`}>
            {children}
        </div>
    )
}


ConfzModalBody.defaultProps = {
    className: ""
}


ConfzModalBody.propTypes = {
    className: PropTypes.string,
}

export { ConfzModalBody }
