import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { PostAuthMenuRoutes } from '../../../routes';
import { useStyles } from './'
import { Can } from '../../../_helpers';
import ability from '../../../_helpers/permissions/ability';


function SideBar({ isDrawerOpen, user }) {

    const classes = useStyles();
    const menuRoutes = Object.values(PostAuthMenuRoutes());

    const location = useLocation();

    return (
        <Drawer variant="permanent" open={isDrawerOpen} className={classes.drawer} >
            <Box className={`${classes.listMenuWrapper} ${isDrawerOpen ? `drawer-open` : ``}`}>
                <List >
                    {menuRoutes.map((route, index) => (
                        <Can key={index} I={route.menuPermission} ability= { ability() } passThrough={((!route.menuPermission) || (user.role_slug === 'developer' && route.menuPermission && ['roles', 'permissions', 'users'].indexOf(route.menuPermission) > -1 )) ? true : false}>
                            <ListItem key={index} button component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``}>
                                <ListItemIcon>
                                    <route.menuIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                            </ListItem>
                        </Can>
                    ))}
                </List>
            </Box>
        </Drawer>
    )
}


const mapStateToProps = (state) => ({
    isDrawerOpen: state.GlobalReducer.isDrawerOpen,
    user: state.UserReducer.user,
})



const connectedSideBar = connect(mapStateToProps, null)(SideBar);
export { connectedSideBar as SideBar };

