import { UserServices } from "../services";
import { cookieServices } from '../../_helpers';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const UserAction = {

    /**
    * Action for get user details
    * @author Akshay 
    */
    getUserDetails: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.getUserDetails()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: USER_REQUEST } }
        function success(response) { return { type: USER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for update user details
    * @author Jasin 
    */
    updateUserDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.updateUserDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: USER_UPDATE_REQUEST, request: request } }
        function success(request, response) { return { type: USER_UPDATE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: USER_UPDATE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for user logout
    * @author Jasin 
    */
    logout: (params) => {
        UserServices.logout();
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("permissions");
        cookieServices.remove('accessToken');
        cookieServices.remove('permissions');
    },

    /**
    * Action to change user password
    * @author Jasin 
    */
     changePassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.changePassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};



