
import { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useHistory } from 'react-router';

import { Box, Grid } from '@mui/material';
import { CopyRight, Header } from '.'

import { useStyles } from '.'

const PreSignIn = ({ children, ...props }) => {

    let history = useHistory();

    useEffect(() => {

        if (cookieServices.get('accessToken')) {
            history.push('/');
        }

    }, [history])

    const classes = useStyles();
    return (
        <Box justifyContent="center" alignItems="center">
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.preSignInWrapper} >
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
                    <Box display="flex" alignItems="center" className={classes.preSignInWrapperItem}>
                        <Box className={classes.preSignInWrapperItemInner}>
                            <Header />
                            {children}
                        </Box>
                    </Box>
                    {/*<CopyRight />*/}
                </Grid>
            </Grid>
        </Box>
    )
}


export { PreSignIn }
