
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Route, Switch } from "react-router-dom";

import { cookieServices } from '../../_helpers';
import { PostAuthMainComponents } from '../../routes';

import { Box } from '@mui/material';

import { Header, SideBar } from './'
import { useStyles } from '.'
import ability from '../../_helpers/permissions/ability';

const PostSignIn = ({ isDrawerOpen, user }) => {

    let history = useHistory();
    const menuRoutes = Object.values(PostAuthMainComponents());

    useEffect(() => {

        if (!cookieServices.get('accessToken')) {
            history.push('/login');
        }

    }, [history])

    const getAbility =  ability();
    const classes = useStyles();
    
    return (
        <Box >
            <Header />
            <SideBar />
            <Box component="main" className={`${classes.mainComponent} ${isDrawerOpen ? classes.drawerOpen : ``}`}>
                <Switch>
                    {menuRoutes.map((route, index) => (
                        <Route exact key={index} path={route.path} component={ ((!route.menuPermission) || (user.role_slug === 'developer' && route.menuPermission && ['roles', 'permissions', 'users'].indexOf(route.menuPermission) > -1 ) || (getAbility.can(route.menuPermission, 'all'))) ? route.component : 'Dashboard' } />
                    ))}
                </Switch>
            </Box>
        </Box>
    )
}


const mapStateToProps = (state) => ({
    isDrawerOpen: state.GlobalReducer.isDrawerOpen,
    user: state.UserReducer.user,
})



const connectedPostSignIn = connect(mapStateToProps, null)(PostSignIn);
export { connectedPostSignIn as PostSignIn };

