import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const EmailLogsServices = {
    /**
     * function get email logs
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/04/2022
     */
    getEmailLogsList: (params) => {
        return apiServices.get(`/email-logs/list`, { params: params })
            .then((response) => {
                return response;
            })
    },
    
    getEmailLogsDetails: (params) => {
        return apiServices.get(`/email-logs/details`, { params: params })
            .then((response) => {
                return response;
            })
    },

    emailLogResendMail: (params) => {
        return apiServices.post(`/email-logs/resend`, { params: params })
            .then((response) => {
                return response;
            })
    },
    
}

