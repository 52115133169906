const validateBrandFee = (name, value, data) => {
    const errors = {};
    switch (name) {
        case 'brand_id': {
            errors.brand_id = '';
            if (value === '')
                errors.brand_id = 'Select brand';
            break;
        } case 'coupon_count': {
            errors.coupon_count = '';
            if (value === '')
                errors.coupon_count = 'Enter monthly coupons';
            break;
        } case 'giveaway_count': {
            errors.giveaway_count = '';
            if (value === '')
                errors.giveaway_count = 'Enter monthly giveaway';
            break;
        } case 'fee': {
            errors.fee = '';
            if (value === '')
                errors.fee = 'Enter monthly fee';
            break;
        }case 'additional_coupon_count': {
            errors.additional_coupon_count = '';
            if (value === '')
                errors.additional_coupon_count = 'Enter additional coupons';
            break;
        }case 'additional_fee': {
            errors.additional_fee = '';
            if (value === '')
                errors.additional_fee = 'Enter additional coupon fee';
            break;
        }case 'additional_giveaway_count': {
            errors.additional_giveaway_count = '';
            if (value === '')
                errors.additional_giveaway_count = 'Enter additional giveaway';
            break;
        }case 'additional_giveaway_fee': {
            errors.additional_giveaway_fee = '';
            if (value === '')
                errors.additional_giveaway_fee = 'Enter additional giveaway fee';
            break;
        }default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateBrandFee;