import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PostAuthRoutes } from '../../routes';
import { Helmet } from "react-helmet";
import { APP_NAME } from '../../_helpers';
import { BusinessAction } from '../../redux/actions'
import { makeStyles } from '@mui/styles';
import { Box, Link, Grid, Card, CardHeader, CardContent, CardMedia, Typography, } from '@mui/material';
import moment from 'moment';
import { BrandCoupons } from './';

const useStyles = makeStyles((theme) => ({
    logoCardMedia: {
        maxWidth: `200px`,
        width: `100%`
    },
    sampleStoryCardMedia: {
        maxWidth: `200px`,
        width: `100%`
    },
    colorBox: {
        border: `1px solid #cfd8dc`,
        width: `25px`,
        height: `20px`
    },
}));

function BrandDetails({getBusinessDetails, getDailyCouponCounts, couponCount, businessDetails, loading, ...props }) {

    const [brandId, setBrandId] = useState(props.match.params.id ?? '');
    useEffect(() => {
        getBusinessDetails({'brandId': brandId});
        getDailyCouponCounts({'brandId': brandId});
    }, [])

    function TimeBetweenPost({ tbp }) {
        if (tbp === 'One Time')
            return (<Typography variant="subtitle1" color="initial"> {'One Time'}</Typography>)
        else if (tbp === '1 Day')
            return (<Typography variant="subtitle1" color="initial"> {'Once a day'}</Typography>)
        else if (tbp === '1 Week')
            return (<Typography variant="subtitle1" color="initial"> {'Once a week'}</Typography>)
        else if (tbp === '1 Month')
            return (<Typography variant="subtitle1" color="initial"> {'Once a month'}</Typography>)
    };

    function getDealType(dealTypeAbbr) {
        var dealType = '';
        switch (dealTypeAbbr) {
            case 'slr': {
                dealType = 'Story - Like'
                break;
            } case 'sler': {
                dealType = 'Story - Like - Email'
                break;
            } case 'lr': {
                dealType = 'Like'
                break;
            } case 'ler': {
                dealType = 'Like - Email'
                break;
            } case 'sleg': {
                dealType = 'Story - Like - Email - Giveaway'
                break;
            } case 'leg': {
                dealType = 'Like - Email - Giveaway'
                break;
            } default: {
                dealType = '';
                break;
            }
        }
        return dealType;
    }

    const classes = useStyles();
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('view_brand_details').name}`}</title>
            </Helmet>

            <Grid container spacing={2} justify="center">
                <Grid item xs={6} md={6}>
                    <Card sx={{ minWidth: 275, marginBottom: '10px' }}>
                        <CardHeader
                            title="Brand Details"
                        />
                        <CardContent>
                            { !loading && <Grid container spacing={2} >
                                <Grid item xs={12} md container spacing={4}>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Company Name</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{businessDetails.company_name}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Public Instagram Handle</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Link href={`https://www.instagram.com/${businessDetails.instagrm}`} variant="body2" target="_blank" rel="noreferrer">
                                            <Typography variant="subtitle1">{`@${businessDetails.instagrm}`}</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Company Representative Name</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{businessDetails.name}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Company Representative Email</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{businessDetails.email}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Company URL</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1"><a rel="noreferrer" target="_blank" href={`${businessDetails.company_url}`}>{businessDetails.company_url}</a></Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Company Phone Number</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{businessDetails.company_phone}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Subdomain</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">
                                            <a href={(businessDetails.brand_domain) ? (`https://${businessDetails.brand_domain}.${process.env.REACT_APP_DOMAIN}`) : ('-')} target="_blank" rel="noreferrer"> {(businessDetails.brand_domain) ? (`https://${businessDetails.brand_domain}.${process.env.REACT_APP_DOMAIN}`) : ('-')}</a>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Deal Available</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <span className={"badge " + (businessDetails.deal_details ? 'badge-success' : 'badge-danger') } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{(businessDetails.deal_details ? 'Yes' : 'No')}</span>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Created On</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{moment(new Date(businessDetails.created_at)).format('MM/DD/YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Card sx={{ minWidth: 275, marginBottom: '10px' }}>
                        <CardHeader
                            title="Coupon Details"
                        />
                        <CardContent>
                            {(!loading && couponCount) && <Grid item xs={12} md container style={{'marginBottom':'50px'}}>
                                <Grid item xs={5} md={5}>
                                    <Typography variant="subtitle1">Total Coupons</Typography>
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <Typography variant="subtitle1">{couponCount.totalCoupons ?? 0}</Typography>
                                </Grid>
                                <Grid item xs={5} md={5}>
                                    <Typography variant="subtitle1">Balance Coupons</Typography>
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <Typography variant="subtitle1">{couponCount.balanceCoupon ?? 0}</Typography>
                                </Grid>
                            </Grid>}
                            <Grid item xs={12} md container>
                                    <BrandCoupons brandId={brandId}/>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2} justify="center">
                <Grid item xs={6} md={6}>
                    {(!loading && businessDetails.deal_details) && <Card sx={{ minWidth: 275, marginBottom: '10px'  }}>
                        <CardHeader
                            title="Deal Details"
                        />
                        <CardContent>
                            { !loading && <Grid container spacing={2} >
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1">Offer Instagram Handle</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Link href={`https://www.instagram.com/${businessDetails.deal_details['insta_handle']}`} variant="body2" target="_blank" rel="noreferrer">
                                    <Typography variant="subtitle1">{`@${businessDetails.deal_details['insta_handle']}`}</Typography>
                                </Link>
                            </Grid>
                            {businessDetails.deal_details['logo'] && (
                                <React.Fragment>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1" color="initial">Logo</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Link href={`${businessDetails.company_url}`} variant="body2" target="_blank" rel="noreferrer">
                                            <CardMedia src={businessDetails.deal_details['logo']} component="img" width="100" alt={`${businessDetails.company_name}`} className={classes.logoCardMedia} />
                                        </Link>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Primary Color</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Box className={classes.colorBox} style={{ backgroundColor: `${(businessDetails.deal_details['primary_color_hex']) ? (businessDetails.deal_details['primary_color_hex']) : ''}` }}> </Box>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Secondary Color</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Box className={classes.colorBox} style={{ backgroundColor: `${(businessDetails.deal_details['secondary_color_hex']) ? (businessDetails.deal_details['secondary_color_hex']) : ''}` }}> </Box>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Link Color</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Box className={classes.colorBox} style={{ backgroundColor: `${(businessDetails.deal_details['link_color_hex']) ? (businessDetails.deal_details['link_color_hex']) : ''}` }}> </Box>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Is this a giveaway?</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Typography variant="subtitle1" color="initial">{businessDetails.deal_details['is_giveaway'] ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            {businessDetails.deal_details['times_between_post'] && (
                                <React.Fragment>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1" color="initial">How Often A User Can Post</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <TimeBetweenPost tbp={businessDetails.deal_details['times_between_post']} />
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Font</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Typography variant="subtitle1" color="initial">{businessDetails.deal_details['font']}</Typography>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Reward Description</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Typography variant="subtitle1" color="initial">{businessDetails.deal_details['reward_description']}</Typography>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <Typography variant="subtitle1" color="initial">Fine Print</Typography>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <Typography variant="subtitle1" color="initial">{businessDetails.deal_details['offer_description']}</Typography>
                            </Grid>
                            {businessDetails.deal_details['deal_type'] && (
                                <React.Fragment>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1" color="initial">Deal Type</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1" color="initial">{getDealType(businessDetails.deal_details['deal_type'])}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}

                            {businessDetails.deal_details['giveaway_date_fmt'] && (
                                <React.Fragment>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1" color="initial">Giveaway ends in</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1" color="initial">{`${businessDetails.deal_details['giveaway_date_fmt']}`}</Typography>
                                    </Grid>
                                </React.Fragment>
                            )}
                            {businessDetails.deal_details['sample_story'] && (
                                <React.Fragment>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1" color="initial">Sample Story</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Link href={`${businessDetails.company_url}`} variant="body2" target="_blank" rel="noreferrer">
                                            <CardMedia src={businessDetails.deal_details['sample_story']} component="img" width="100" alt={`${businessDetails.company_name}`} className={classes.sampleStoryCardMedia} />
                                        </Link>
                                    </Grid>
                                </React.Fragment>
                            )}
                            </Grid> }
                        </CardContent>
                    </Card>}
                </Grid>
                <Grid item xs={6} md={6} direction="column" justifyContent="center" alignItems="center">
                    {(!loading && businessDetails.deal_details) && <Card>
                        <CardHeader
                            title="Deal Preview"
                        />
                        <CardContent>
                            <Box >
                                <Grid container direction="column" justifyContent="center" alignItems="center" >
                                    <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                        <iframe title="insta story" width="414" height="896" src={`https://${businessDetails.brand_domain}.${process.env.REACT_APP_DOMAIN}`}  frameBorder="0"></iframe>
                                    </Grid>
                                    
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>}
                </Grid>
            </Grid>
            
        
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    businessDetails: state.BusinessReducer.businessData,
    loading: state.BusinessReducer.loading,
    couponCount: state.BusinessReducer.couponCount,
})


function mapDispatchToProps(dispatch) {
    return {
        getBusinessDetails: (params) => dispatch(BusinessAction.getBusinessDetails(params)),
        getDailyCouponCounts: (params) => dispatch(BusinessAction.getDailyCouponCounts(params))
    }
};


const connectedBrandDetails= connect(mapStateToProps, mapDispatchToProps)(BrandDetails);
export { connectedBrandDetails as BrandDetails };
