import { useEffect, useState } from 'react'
// import { UsersDataTable } from './UsersDataTable';
import { AddUpdateUser, UsersDataTable } from './'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { UpdateStatusModal } from '../../_components/modal';
import { connect } from 'react-redux';
import { ManageUsersAction } from '../../redux/actions';
import ability from '../../_helpers/permissions/ability';
import { Can } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
    addUserButton: {
        "float" : "right"
    }
}));

/**
  * 
  * @author Jasin 
  */
function ManageUsers({updateStatus}) {
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [userId, setUserId] = useState('');
    const classes = useStyles();
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const addUpdateUserModal = (id='') => {
        setUserId(id ?? '');
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    const statusUpdateModal = ({id, status, item}) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    const updateStatusAction = (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        updateStatus({ 'id': id });
    }
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Can I='add_update_users' ability= { ability() }>
                            <Button variant="outlined" startIcon={<AddIcon />} className={classes.addUserButton} onClick={() => addUpdateUserModal()}>
                                Add User
                            </Button>
                        </Can>
                        <AddUpdateUser show={showAddUpdateModal} closeModal={addUpdateUserModal} id={userId}/>
                        <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction}  />
                    </Grid>
                    <Grid item xs={12}>
                        <UsersDataTable addUpdateUserModal = {addUpdateUserModal} statusUpdateModal = {statusUpdateModal} />
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.ManageUsersReducer.loading,
        user: state.ManageUsersReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateStatus: (params) => dispatch(ManageUsersAction.updateStatus(params))
    }
};

const connectedManageUsers = (connect(mapStateToProps, mapDispatchToProps)(ManageUsers));
export { connectedManageUsers as ManageUsers };
