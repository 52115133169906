import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { DataTable } from '../../_components/DataTable/DataTable';
import { DataTableLoader } from '../../_components/loaders/DataTableLoader';
import { ManageUsersAction } from '../../redux/actions';
import queryString from 'query-string';
import { createTheme, IconButton, ListItemIcon, Menu, MenuItem, TableCell, TableSortLabel, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Check, Close, ModeEdit, MoreHoriz } from '@mui/icons-material';
import { Can } from '../../_helpers';
import ability from '../../_helpers/permissions/ability';


const useStyles = makeStyles((theme) => ({
    menuPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
        '& .MuiList-root': {
            padding: 6,
            minWidth: 100
        },
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiDivider-root': {
            marginTop: 3,
            marginBottom: 3
        }
    }
}));

/**
  * 
  * @author Jasin 
  */
function UsersDataTable({getUsersList, loading, user, total, per_page, addUpdateUserModal, statusUpdateModal, ...props}) {

    const [adminUserId, setAdminUserId] = useState('');    
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState(0);
    const [filter, setFilter] = useState([]);
    const [page, setPage] = useState(0);

    const [userList, setUserList] = useState([]);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleActionClick = (event, value, status) => {
        setAnchorEl(event.currentTarget);
        setAdminUserId(value);
        setStatusData({ id: value, status: status, item: '' });
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    function handleEditClick() {      
        setAnchorEl(null);
        setTimeout(() => {
            addUpdateUserModal(adminUserId)
        }, 100);
    }

    function handleStatusChangeClick() {      
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }
    
    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                sort: true,
                filter: false,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
                filter: false,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
       {
           name: "role",
           label: "Role",
           options: {
               sort: true,
               filter: false,
               customHeadRender: ({index, name,...column}, sortColumn) => {
                   return (
                     <TableCell onClick={() => sortColumn(index)} key={index}>
                       <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                     </TableCell>
                   );
               },
           }
       },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                    <>
                        <span className={"badge " + (value == 'inactive' ? 'badge-danger' : 'badge-success') } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{(value == 'inactive' ? 'Inactive' : 'Active')}</span> 
                        <br />
                    </>
                    )
                }
            }
        },
        {
            name: "_id",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' }),
                customBodyRender: (value, dataIndex) => {
                    var adminUserStatus = userList[dataIndex.rowIndex].status === 'inactive' ? 'inactive' : 'active';
                    return (
                        <>
                            <IconButton
                                aria-label="more"
                                id="action-button"
                                aria-controls="action-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={event => handleActionClick(event, value, adminUserStatus)}
                            >
                                <MoreHoriz />
                            </IconButton>

                            <Menu
                                id="action-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleActionClose}
                                MenuListProps={{
                                'aria-labelledby': 'action-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    className: classes.menuPaper,
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >   
                                <Can I='add_update_users' ability= { ability() }>
                                    <MenuItem
                                        onClick={handleEditClick}
                                    >
                                        <ListItemIcon>
                                            <ModeEdit fontSize="small" />
                                        </ListItemIcon>
                                        Edit

                                    </MenuItem>
                                </Can>
                                <Can I='update_user_status' ability= { ability() }>
                                    <MenuItem
                                        onClick={handleStatusChangeClick}
                                    >
                                        <ListItemIcon>
                                        {statusData.status === 'inactive' ?  <Check fontSize="small" /> :  <Close fontSize="small" />}
                                    
                                        </ListItemIcon>
                                        {statusData.status === 'inactive' ? 'Activate' : 'Inactivate'}

                                    </MenuItem>
                                </Can>
                            </Menu>
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        filter: true,
        resonsive: 'stacked',
        print: false, //PDF
        download: false, //CSV
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: per_page,
        rowsPerPageOptions: [10, 25, 50, 100],
        count:total,
        filterType: "dropdown",
        serverSide : true,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page)
                  break;
                case 'sort':
                    setSort(tableState.sortOrder)
                  break;
                case 'search':
                    setSearch(tableState.searchText);
                  break;
                case 'filterChange':
                    setFilter(tableState.filterList)
                  break;
                default:
                  console.log('action not handled.');
            }

        }
    };

    useEffect(() => {
        let queryParam = {};

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/manage-users?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        getUsersList({ page: page, search: search, sortOrder: sort, filter: filter }).then((response) => {
            setUserList(response.data.users ?? []);
        });;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort, user])

    const getMuiTheme = () => createTheme({
        components: {
            // @ts-ignore custom component
            MUIDataTableToolbar: {
              styleOverrides: {
                filterPaper: {
                    width: "600px",
                },
              },
            },
        },
    })
    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <DataTable
                    title={"Users"}
                    data={userList}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        // userList: state.ManageUsersReducer.data,
        loading: state.ManageUsersReducer.loading,
        user: state.ManageUsersReducer.user,
        page: state.ManageUsersReducer.page,
        per_page: state.ManageUsersReducer.per_page,
        total: state.ManageUsersReducer.total,
        total_pages: state.ManageUsersReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUsersList: (params) => dispatch(ManageUsersAction.getUsersList(params))
    }
};

const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(UsersDataTable));
export { connectedDataTable as UsersDataTable };