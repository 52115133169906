import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: `#fff !important`,
    },
    pageLogo: {
        marginLeft: `5px`
    },
    pageTitle: {
        marginLeft: `10px !important`,
        minWidth: 200
    }
}))

export { useStyles };
