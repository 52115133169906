import "react-datepicker/dist/react-datepicker.css";
import React, { forwardRef, useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useStyles } from '.'
import DatePicker from "react-datepicker";
import moment from "moment";

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    type: 'date',
    isReadOnly: false,
    maxLength: 255,
    className: '',
    placeholder: '',
    clearFilter: false
};

const FormDatepicker = ({ name, label, placeholder, type, className, id, value, onChange, isReadOnly, maxLength, tabIndex, error, info, clearFilter, ...props }) => {
    const [startDate, setStartDate] = useState(value ? value : null);

    const classes = useStyles();

    const handleChange = (e) => {
        setStartDate(e)
        onChange({ type: `date`, name: name, value: moment(e).format('yyyy-MM-DDTHH:mm') });
    }
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <OutlinedInput
            className={classes.input}
            aria-describedby={`helper_${name}`}
            name={name}
            value={value}
            onClick={onClick}
            ref={ref}
            label={label}
            error={error ? true : false}
            {...props}
        />
      ));

    useEffect(() => {
        if(clearFilter) {
            setStartDate(null)
        }
    }, [clearFilter])
    
    return (
        <FormControl fullWidth className={classes.formControl} {...props} >
            <InputLabel htmlFor={name} className={classes.label}>{label}</InputLabel>
            <DatePicker
                selected={isReadOnly ? '' : startDate}
                onChange={handleChange}
                dateFormat="MMMM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                popperClassName={classes.popperClass}
                isClearable={true}
                customInput={<ExampleCustomInput />}

            />
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    );
};

FormDatepicker.defaultProps = defaultProps;

export { FormDatepicker };
