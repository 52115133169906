import { 
    GET_PERMISSION_LIST_REQUEST, GET_PERMISSION_LIST_SUCCESS, GET_PERMISSION_LIST_FAILURE,
    PERMISSION_LIST_SELECT_REQUEST, PERMISSION_LIST_SELECT_SUCCESS, PERMISSION_LIST_SELECT_FAILURE,
    ADD_PERMISSION_REQUEST, ADD_PERMISSION_SUCCESS, ADD_PERMISSION_FAILURE,
    UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS, UPDATE_PERMISSION_FAILURE,
    UPDATE_PERMISSION_STATUS_REQUEST, UPDATE_PERMISSION_STATUS_SUCCESS, UPDATE_PERMISSION_STATUS_FAILURE

} from '../actions'

const initialState = { 
    loading: false, request: {}, data: [], errors: {}, permission: {}, totalCount: 0,
    permissionListSelect:[], permissionListSelectloading:false
}


export function PermissionReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get permission list redux 
        case GET_PERMISSION_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_PERMISSION_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
                totalCount: action.totalCount
            }
        }
        case GET_PERMISSION_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::Get permission list redux 

        //  begin::Get permission list select redux 
        case PERMISSION_LIST_SELECT_REQUEST: {
            return {
                ...state,
                permissionListSelectloading: true,
                request: action.request,
            }
        }
        case PERMISSION_LIST_SELECT_SUCCESS: {
            return {
                ...state,
                permissionListSelectloading: false,
                permissionListSelect: action.data,
            }
        }
        case PERMISSION_LIST_SELECT_FAILURE: {
            return {
                ...state,
                permissionListSelectloading: false,
                errors: action.errors,
            }
        }
        // begin::Get permission list select redux 

        //  begin::add permission redux 
        case ADD_PERMISSION_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case ADD_PERMISSION_SUCCESS: {
            return {
                ...state,
                loading: false,
                permission: action.data,
            }
        }
        case ADD_PERMISSION_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add permission redux 

        //  begin::update permission redux 
        case UPDATE_PERMISSION_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case UPDATE_PERMISSION_SUCCESS: {
            return {
                ...state,
                loading: false,
                permission: action.data,
            }
        }
        case UPDATE_PERMISSION_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add permission redux 

        //  begin::update permisssion status redux 
        case UPDATE_PERMISSION_STATUS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case UPDATE_PERMISSION_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                permission: action.data,
            }
        }
        case UPDATE_PERMISSION_STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::update permisssion status redux 
        default:
            return state
    }

}
