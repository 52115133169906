import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ConfzModal, ConfzModalHeader, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../_components/modal';
import { FormInput, FormPassword, FormSelect } from '../../_components/form';
import { validateForm } from '../../_helpers';
import { Grid } from '@mui/material';
import validateUser from './validateUser';
import { ManageUsersAction, RoleAction } from '../../redux/actions';

const inputs = { user_id: '', first_name: '', last_name: '', email: '', password: '', role_id: '', role: ''};

function AddUpdateUser({ id, show, closeModal, addUser, getUser, getRoleListSelect, roleListSelect, roleListSelectloading }) {
    const [data, setData] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getUser({'id': id}).then((response) => {
                if (response.status === 1) {
                    setData({
                        user_id: response.data._id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email: response.data.email,
                        role: response.data.role,
                        role_id: response.data.role_id,
                    });
                }
                setShowLoader(false);
            });
        } else {
            setData({ ...inputs });
        }
        getRoleListSelect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onHide = () => {
        setData({ ...inputs })
        setErrors({ ...inputs })
        closeModal(false);
    }

    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validateUser(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateUser(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        console.log(valid, 'valid');
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchUserAction(data);
    }

    const dispatchUserAction = (data) => {
        let dispatchAction = addUser(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setData({ ...inputs })
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
            <React.Fragment>
                <ConfzModal isModalOpen={show} onHide={onHide}  size="lg" loader={showLoader}>
                    <form onSubmit={handleSubmit} noValidate >
                        <ConfzModalHeader id="AddUpdateUser" title={(id) ? 'Update User' : 'Add User'} onHide={onHide} />
                        <ConfzModalBody>
                        {!showLoader &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormInput
                                        tabIndex={1}
                                        label='First name'
                                        name='first_name'
                                        autoFocus={true}
                                        onChange={handleChange}
                                        value={data.first_name}
                                        error={(action.isSubmitted && (errors.first_name)) ? (errors.first_name) : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormInput
                                        tabIndex={2}
                                        label='Last name'
                                        name='last_name'
                                        onChange={handleChange}
                                        value={data.last_name}
                                        error={(action.isSubmitted && (errors.last_name)) ? (errors.last_name) : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormInput
                                        tabIndex={3}
                                        label='Email'
                                        name='email'
                                        onChange={handleChange}
                                        value={data.email}
                                        error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                    />
                                </Grid>
                                {!id && 
                                    <Grid item xs={12} sm={6}>
                                        <FormPassword
                                            tabIndex={4}
                                            label='Password'
                                            name='password'
                                            onChange={handleChange}
                                            value={data.password}
                                            error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} sm={!id ? 12 : 6}>
                                    <FormSelect
                                        tabIndex={5}
                                        label='Role'
                                        name='role_id'
                                        onChange={handleChange}
                                        value= {{value: data.role_id, name: data.role}}
                                        error={(action.isSubmitted && (errors.role_id)) ? (errors.role_id) : ''}
                                        options = {!roleListSelectloading ? roleListSelect : []}
                                    />
                                </Grid>
                            </Grid>
                        }
                        </ConfzModalBody>
                        <ConfzModalFooter>
                            <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                            <ConfzModalFooterSaveBtn tabIndex="3" />
                        </ConfzModalFooter>
                    </form>
                </ConfzModal>
            </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        roleListSelect: state.RoleReducer.roleListSelect,
        roleListSelectloading: state.RoleReducer.roleListSelectloading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addUser: (params) => dispatch(ManageUsersAction.addUser(params)),
        getUser: (params) => dispatch(ManageUsersAction.getUser(params)),
        getRoleListSelect: () =>dispatch(RoleAction.getRoleListSelect()),
    }
};

const connectedAddUpdateUser = connect(mapStateToProps, mapDispatchToProps)(AddUpdateUser);
export { connectedAddUpdateUser as AddUpdateUser };

