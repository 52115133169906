import React, { useState } from 'react'

import { AccountCircle, Logout, Settings } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, MenuItem, Menu } from '@mui/material';

import { useStyles } from '.'
import { Link, Redirect } from 'react-router-dom';
import { PostAuthRoutes } from '../../../../routes';
import { UserAction } from '../../../../redux/actions';

function AccountMenu() {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [logoutStatus, setLogoutStatus] = useState(false);

    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutAction = (e) => {
        e.preventDefault();
        UserAction.logout();
        setLogoutStatus(true);
    }

    return (
        <React.Fragment>
            {logoutStatus && <Redirect to="/login" />}
            {!logoutStatus && (
                <>
                    <IconButton onClick={handleClick}>
                    <AccountCircle />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            className: classes.menuPaper,
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem component={Link} to={PostAuthRoutes('profile_details').path}>
                            <ListItemIcon>{React.createElement(PostAuthRoutes('profile_details').menuIcon)} </ListItemIcon>
                            {PostAuthRoutes('profile_details').name}
                        </MenuItem>
                        <Divider />
                        {/*<MenuItem>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>*/}
                        <MenuItem onClick={logoutAction}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            )}
            
        </React.Fragment>
    )
}

export { AccountMenu }
