import { ForgotPassword, Register, SignIn, SignInPassword } from "../app/pre-signin";

const allRoutes = {
    'register': {
        path: "/register",
        name: "Create account",
        component: Register,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'login_password': {
        path: "/login/pw",
        name: "Log in",
        component: SignInPassword,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'login': {
        path: "/login",
        name: "Log in",
        component: SignIn,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'forgot_password': {
        path: "/password-recovery",
        name: "Forgot password?",
        component: ForgotPassword,
        layout: "/pre-auth",
        isMainComponent: true
    },
};


function PreAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PreAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PreAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PreAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            console.log(item.path,path);
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}

export { PreAuthRoutes, PreAuthMenuRoutes, PreAuthModuleMenuRoutes, PreAuthMainComponents, getComponentNameByPath };