import { 
    GET_INVOICE_LIST_REQUEST, GET_INVOICE_LIST_SUCCESS, GET_INVOICE_LIST_FAILURE,
} from '../actions'

const initialState = { 
    loading: false, request: {}, data: [{}], errors: {}, totalCount: 0
}


export function InvoiceReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get invoice list redux 
        case GET_INVOICE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_INVOICE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
                totalCount: action.totalCount
            }
        }
        case GET_INVOICE_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::Get invoice list redux 

        default:
            return state
    }

}
