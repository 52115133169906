import { 
    ADD_ROLE_REQUEST, ADD_ROLE_SUCCESS, ADD_ROLE_FAILURE,
    UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE,
    GET_ROLE_LIST_REQUEST, GET_ROLE_LIST_SUCCESS, GET_ROLE_LIST_FAILURE,
    GET_ROLE_REQUEST, GET_ROLE_SUCCESS, GET_ROLE_FAILURE,
    UPDATE_ROLE_STATUS_REQUEST, UPDATE_ROLE_STATUS_SUCCESS, UPDATE_ROLE_STATUS_FAILURE,
    ROLE_LIST_SELECT_REQUEST, ROLE_LIST_SELECT_SUCCESS, ROLE_LIST_SELECT_FAILURE,

} from '../actions'

const initialState = { 
    loading: false, request: {}, data: [], errors: {}, role: {}, totalCount: 0,
    roleListSelect:[], roleListSelectloading:false
}


export function RoleReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::add role redux 
        case ADD_ROLE_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case ADD_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                role: action.data,
            }
        }
        case ADD_ROLE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add role redux 

        //  begin::update role redux 
        case UPDATE_ROLE_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case UPDATE_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                role: action.data,
            }
        }
        case UPDATE_ROLE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::add role redux 

        //  begin::Get role list redux 
        case GET_ROLE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_ROLE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
                totalCount: action.totalCount
            }
        }
        case GET_ROLE_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::Get role list redux 

        

        //  begin::get role redux 
        case GET_ROLE_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case GET_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                role: action.data,
            }
        }
        case GET_ROLE_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get role redux 

        //  begin::get role redux 
        case UPDATE_ROLE_STATUS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,
            }
        }
        case UPDATE_ROLE_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                role: action.data,
            }
        }
        case UPDATE_ROLE_STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors,
            }
        }
        // begin::get role redux 

        //  begin::Get role list select redux 
        case ROLE_LIST_SELECT_REQUEST: {
            return {
                ...state,
                roleListSelectloading: true,
                request: action.request,
            }
        }
        case ROLE_LIST_SELECT_SUCCESS: {
            return {
                ...state,
                roleListSelectloading: false,
                roleListSelect: action.data,
            }
        }
        case ROLE_LIST_SELECT_FAILURE: {
            return {
                ...state,
                roleListSelectloading: false,
                errors: action.errors,
            }
        }
        // begin::Get role list select redux 
        default:
            return state
    }

}
