import { RoleServices } from "../services";

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAILURE = 'GET_ROLE_LIST_FAILURE';

export const ASSIGN_PERMISSION_REQUEST = 'ASSIGN_PERMISSION_REQUEST';
export const ASSIGN_PERMISSION_SUCCESS = 'ASSIGN_PERMISSION_SUCCESS';
export const ASSIGN_PERMISSION_FAILURE = 'ASSIGN_PERMISSION_FAILURE';

export const GET_ASSIGNED_PERMISSIONS_REQUEST = 'GET_ASSIGNED_PERMISSIONS_REQUEST';
export const GET_ASSIGNED_PERMISSIONS_SUCCESS = 'GET_ASSIGNED_PERMISSIONS_SUCCESS';
export const GET_ASSIGNED_PERMISSIONS_FAILURE = 'GET_ASSIGNED_PERMISSIONS_FAILURE';

export const UPDATE_ROLE_STATUS_REQUEST = 'UPDATE_ROLE_STATUS_REQUEST';
export const UPDATE_ROLE_STATUS_SUCCESS = 'UPDATE_ROLE_STATUS_SUCCESS';
export const UPDATE_ROLE_STATUS_FAILURE = 'UPDATE_ROLE_STATUS_FAILURE';

export const ROLE_LIST_SELECT_REQUEST = 'ROLE_LIST_SELECT_REQUEST';
export const ROLE_LIST_SELECT_SUCCESS = 'ROLE_LIST_SELECT_SUCCESS';
export const ROLE_LIST_SELECT_FAILURE = 'ROLE_LIST_SELECT_FAILURE';

export const RoleAction = {

    /**
     * function set add rolea
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
    */
    addRole: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.addRole(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_ROLE_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_ROLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_ROLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set update rolea
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
    */
    updateRole: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.updateRole(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_ROLE_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_ROLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_ROLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function get role
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
    */
    getRole: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.getRole(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ROLE_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ROLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ROLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set get role list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 20/01/2022
     */
    getRoleList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return RoleServices.getRoleList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_ROLE_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_ROLE_LIST_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_ROLE_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function assign permission
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    assignPermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.assignPermission(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ASSIGN_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: ASSIGN_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ASSIGN_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    /**
     * function get assigned permissionS
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    getAssignedPermissions: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.getAssignedPermissions(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ASSIGNED_PERMISSIONS_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ASSIGNED_PERMISSIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ASSIGNED_PERMISSIONS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    /**
     * function update role status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    updateRoleStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RoleServices.updateRoleStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_ROLE_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_ROLE_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_ROLE_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function get role list for select
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    getRoleListSelect: () => {
        return dispatch => {
            dispatch(request());
            return RoleServices.getRoleListSelect()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: ROLE_LIST_SELECT_REQUEST } }
        function success(response) { return { type: ROLE_LIST_SELECT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: ROLE_LIST_SELECT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
    
}


