import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { APP_NAME, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';

import { Box, Grid } from '@mui/material';
import { useStyles } from '.'
import { SignInAction } from '../../../redux/actions';

// Initialize form input values to null
const inputs = { email: '', password: '' };

/**
 * function to validate the signin password form inputs
 * @param {string} name Input name
 * @param {string} value Input value
 * @param {object} [data={}] All input values stored in the state
 * @author Akshay N
 */
const validate = (name, value) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'password': {
            errors.password = '';
            if (value === '')
                errors.password = 'Enter your password';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}


function SignInPassword({ signIn, signInPassword, signinErrors, ...props }) {

    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    useEffect(() => {
        checkEmail();
        //eslint-disable-next-line
    }, [])

    /**
      * function to check email exists and assign to data
      * @author Akshay N
      */
    async function checkEmail() {
        const query = new URLSearchParams(location.search);
        let queryEmail = query.get('e');
        if (!queryEmail)
            history.push('/');

        const isSignin = await signIn({ email: queryEmail });
        console.log(isSignin);
        if (isSignin.status === 1)
            setData({ ...data, email: queryEmail })
        else
            history.push('/');

    }

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validate(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInPasswordAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    async function dispatchSignInPasswordAction() {
        const isSignin = await signInPassword(data);
        if (isSignin && isSignin.status === 1) {
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : '/');
            history.push(redirectPath);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('login_password').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} p="0">
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="left" className={classes.forgotPasswordAction}>
                        {/*<RouteLink to={PreAuthRoutes('forgot_password').path} label={PreAuthRoutes('forgot_password').name} />*/}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="right" className={classes.actionBox}>
                        {/*<RouteLink to={PreAuthRoutes('register').path} label={PreAuthRoutes('register').name} />*/}
                        <SubmitButton label={'Next'} fullWidth />
                    </Box>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    signinErrors: state.SignInReducer.password_errors,
})


function mapDispatchToProps(dispatch, ownProps) {
    return {
        signIn: (params) => dispatch(SignInAction.signIn(params)),
        signInPassword: (params) => dispatch(SignInAction.signInPassword(params))
    }
};


const connectedSignInPassword = connect(mapStateToProps, mapDispatchToProps)(SignInPassword);
export { connectedSignInPassword as SignInPassword };
