import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const InvoiceServices = {
    /**
     * function get invoice list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 28/12/2021
     */
    getInvoiceList: (params) => {
        return apiServices.get(`/invoice/get-invoice-list`, { params: params })
            .then((response) => {
                return response;
            })
    },
}

