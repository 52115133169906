import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { FormInput, FormSelect } from '../../../_components/form';
import { validateForm } from '../../../_helpers';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { PermissionAction } from '../../../redux/actions';
import validatePermission from './validatePermission';
import { SnackBarAlert } from '../../../_components/SnackBarAlert/SnackBarAlert';

const inputs = { permission_id:'', permission: '', parent: '', parent_id: ''};

function AddUpdatePermission({ id, show, closeModal, permissionListSelect, getPermissionListSelect, permissionListSelectloading, addPermission, updatePermission, getPermission}) {
    const [data, setData] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getPermission({'id': id}).then((response) => {
                if (response.status === 1) {
                    setData({
                        permission_id: id,
                        permission: response.data.permission,
                        parent: response.data.parent,
                        parent_id: response.data.parent_id,
                    });
                }
                setShowLoader(false);
            });
        } else {
            setData({ ...inputs });
        }
        getPermissionListSelect();
    }, [id])

    const onHide = () => {
        setData({ ...inputs })
        setErrors({ ...inputs })
        closeModal(false);
    }

    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validatePermission(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validatePermission(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchPermissionAction(data);
    }

    const dispatchPermissionAction = (data) => {
        setSnackBar({ type: '', message: '' })
        let dispatchAction = id ? updatePermission(data) : addPermission(data);

        dispatchAction.then((response) => {
            if (response.status === 1) {
                setData({ ...inputs })
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                setSnackBar({type:'success', message:response.message})
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }
    

    return (
        <React.Fragment>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <ConfzModal isModalOpen={show} onHide={onHide}  size="md" loader={showLoader}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="AddUpdatePermission" title={(id) ? 'Update Permission' : 'Add Permission'} onHide={onHide} />
                    <ConfzModalBody>
                    {!showLoader &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Permission'
                                    name='permission'
                                    onChange={handleChange}
                                    value={data.permission}
                                    error={(action.isSubmitted && (errors.permission)) ? (errors.permission) : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormSelect
                                    tabIndex={1}
                                    label='Parent Permission'
                                    name='parent_id'
                                    onChange={handleChange}
                                    value= {{value: data.parent_id, name: data.parent}}
                                    error={(action.isSubmitted && (errors.parent_id)) ? (errors.parent_id) : ''}
                                    options = {!permissionListSelectloading ? permissionListSelect : []}
                                />
                            </Grid>
                        </Grid>
                    }
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn onHide={onHide} />
                        <ConfzModalFooterSaveBtn />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        permissionListSelect: state.PermissionReducer.permissionListSelect,
        permissionListSelectloading: state.PermissionReducer.permissionListSelectloading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPermissionListSelect: () =>dispatch(PermissionAction.getPermissionListSelect()),

        addPermission: (params) => dispatch(PermissionAction.addPermission(params)),
        updatePermission: (params) => dispatch(PermissionAction.updatePermission(params)),
        getPermission: (params) => dispatch(PermissionAction.getPermission(params))
    }
};

const connectedAddUpdatePermission = connect(mapStateToProps, mapDispatchToProps)(AddUpdatePermission);
export { connectedAddUpdatePermission as AddUpdatePermission };

