import { PermissionServices } from "../services";

export const GET_PERMISSION_LIST_REQUEST = 'GET_PERMISSION_LIST_REQUEST';
export const GET_PERMISSION_LIST_SUCCESS = 'GET_PERMISSION_LIST_SUCCESS';
export const GET_PERMISSION_LIST_FAILURE = 'GET_PERMISSION_LIST_FAILURE';

export const PERMISSION_LIST_SELECT_REQUEST = 'PERMISSION_LIST_SELECT_REQUEST';
export const PERMISSION_LIST_SELECT_SUCCESS = 'PERMISSION_LIST_SELECT_SUCCESS';
export const PERMISSION_LIST_SELECT_FAILURE = 'PERMISSION_LIST_SELECT_FAILURE';

export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAILURE = 'ADD_PERMISSION_FAILURE';

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';

export const PERMISSION_HIERARCHY_REQUEST = 'PERMISSION_HIERARCHY_REQUEST';
export const PERMISSION_HIERARCHY_SUCCESS = 'PERMISSION_HIERARCHY_SUCCESS';
export const PERMISSION_HIERARCHY_FAILURE = 'PERMISSION_HIERARCHY_FAILURE';

export const UPDATE_PERMISSION_STATUS_REQUEST = 'UPDATE_PERMISSION_STATUS_REQUEST';
export const UPDATE_PERMISSION_STATUS_SUCCESS = 'UPDATE_PERMISSION_STATUS_SUCCESS';
export const UPDATE_PERMISSION_STATUS_FAILURE = 'UPDATE_PERMISSION_STATUS_FAILURE';

export const PermissionAction = {

    
    /**
     * function set get permission list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
     */
    getPermissionList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return PermissionServices.getPermissionList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_PERMISSION_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_PERMISSION_LIST_SUCCESS, request, message: response.message, data: response.data, totalCount: response.count, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_PERMISSION_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function get permission list for select
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
     */
     getPermissionListSelect: () => {
        return dispatch => {
            dispatch(request());
            return PermissionServices.getPermissionListSelect()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: PERMISSION_LIST_SELECT_REQUEST } }
        function success(response) { return { type: PERMISSION_LIST_SELECT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: PERMISSION_LIST_SELECT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set add permission
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
    */
    addPermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.addPermission(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set update permission
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
    */
    updatePermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.updatePermission(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function get permission
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
    */
    getPermission: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.getPermission(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_PERMISSION_REQUEST, request: request } }
        function success(request, response) { return { type: GET_PERMISSION_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_PERMISSION_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function get permission hierarchy
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
    */
    getPermissionHierarchy: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.getPermissionHierarchy(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: PERMISSION_HIERARCHY_REQUEST, request: request } }
        function success(request, response) { return { type: PERMISSION_HIERARCHY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: PERMISSION_HIERARCHY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function update permission status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    updatePermissionStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return PermissionServices.updatePermissionStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_PERMISSION_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_PERMISSION_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_PERMISSION_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
    
}


