import React from 'react'
import { Link } from 'react-router-dom';
import { useStyles } from '.'

function RouteLink({ to, label, props }) {

    const classes = useStyles();

    return (
        <Link to={to} className={classes.Link} {...props}>{label}</Link>
    )
}

export { RouteLink }
