import { Home, LocalOffer, Notifications, Password, Person, RowingOutlined, Warning, Business, PersonOutline, Visibility, MonetizationOn, ManageAccounts, Receipt, Badge, Key, Email } from "@mui/icons-material";
import { Dashboard } from "../app/dashboard";
import { Business as BusinessComponent, BrandDetails } from "../app/business";
import { AccountStatus, ChangePassword, Profile, ProfileDetails, ProfileUpdate } from "../app/profile";
import { ManageUsers as UsersComponent } from "../app/manage-users";
import { BrandFee as brandFeeComponent } from "../app/brand-fee";
import { Invoice as invoiceComponent } from "../app/invoice";
import { Role, Permission, AssignPermission } from "../app/user-management";
import { EmailLogs, EmailLogsDetails } from "../app/email-logs";

const allRoutes = {
    'home': {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'dashboard',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'dashboard'
    },
//    'deal': {
//        path: "/deal",
//        name: "Deal",
//        component: Dashboard,
//        layout: "/post-auth",
//        isMenuItem: true,
//        menuIcon: LocalOffer,
//        module: 'deal',
//        isModuleMenuItem: true,
//        isMainComponent: true
//    },
    'business': {
        path: "/business",
        name: "Brands",
        component: BusinessComponent,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Business,
        module: 'business',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'brands'
    },
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true

    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: ProfileDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,

    },
    'profile_update': {
        path: "/profile/update",
        name: "Profile Update",
        component: ProfileUpdate,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: ManageAccounts,
        module: 'profile',
        isModuleMenuItem: true,

    },
//    'notifications': {
//        path: "/profile/notifications",
//        name: "Notifications",
//        component: ProfileDetails,
//        layout: "/post-auth",
//        menuIcon: Notifications,
//        isMenuItem: false,
//        module: 'profile',
//        isModuleMenuItem: true
//    },
//    'activity_log': {
//        path: "/profile/activity",
//        name: "Activity Log",
//        component: ProfileDetails,
//        layout: "/post-auth",
//        menuIcon: RowingOutlined,
//        isMenuItem: false,
//        module: 'profile',
//        isModuleMenuItem: true
//    },
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
//    'account_status': {
//        path: "/profile/status",
//        name: "Account Status",
//        component: AccountStatus,
//        layout: "/post-auth",
//        menuIcon: Warning,
//        isMenuItem: false,
//        module: 'profile',
//        isModuleMenuItem: true
//    },
    'manage_users': {
        path: "/manage-users",
        name: "Manage Users",
        component: UsersComponent,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: PersonOutline,
        module: 'users',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'users'
    },
    'view_brand_details': {
        path: "/view-brand/:id",
        name: "View Brand Details",
        component: BrandDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Visibility,
        module: 'business',
        isMainComponent: true
    },
    'brand_fee': {
        path: "/brand-fee",
        name: "Brand Fee",
        component: brandFeeComponent,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: MonetizationOn,
        module: 'brand-fee',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'brand_fee'
    },
    'invoice_details': {
        path: "/invoice-details",
        name: "Invoice",
        component: invoiceComponent,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Receipt,
        module: 'invoice',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'invoice'
    },
    'roles': {
        path: "/roles",
        name: "Roles",
        component: Role,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Badge,
        module: 'user-management',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'roles'
    },
    'permissions': {
        path: "/permission",
        name: "Permissions",
        component: Permission,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Key,
        module: 'user-management',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'permissions'
    },
    'assign_permission': {
        path: "/roles/assign-permission/:id",
        name: "Assign Permission",
        component: AssignPermission,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Key,
        module: 'user-management',
        isMainComponent: true
    },
    'email_logs': {
        path: "/email-logs",
        name: "Email Logs",
        component: EmailLogs,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Email,
        module: 'email-logs',
        isModuleMenuItem: true,
        isMainComponent: true,
        menuPermission: 'email_logs'
    },
    'email_logs_details': {
        path: "/view-email-logs/",
        name: "Email Logs Details",
        component: EmailLogsDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Visibility,
        module: 'email-logs',
        isMainComponent: true
    },
};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };
