const validateRole = (name, value, data) => {
    const errors = {};
    switch (name) {
        case 'role': {
            errors.role = '';
            if (value === '')
                errors.role = 'Enter role';
            break;
        }default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateRole;