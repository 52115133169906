import { apiServices } from "../../_helpers";

export const DashboardServices = {
    /**
       * Service for Dashboard cards display
       * @author Ashwanth
       */
    getBusinessesList: () => {
        return apiServices.get(`/dashboard/businesses`)
            .then((response) => {
                return response;
            })
    },

    getDealsList: () => {
        return apiServices.get(`/dashboard/deals`)
            .then((response) => {
                return response;
            })
    },

    getShopperList: () => {
        return apiServices.get(`/dashboard/shopper`)
            .then((response) => {
                return response;
            })
    }
}