import { useEffect, useState } from 'react'
import { AddUpdateBrandFee, BrandFeeDatatable } from '.'
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { Can } from '../../_helpers';
import ability from '../../_helpers/permissions/ability';

const useStyles = makeStyles((theme) => ({
    addBrandFeeButton: {
        "float" : "right"
    }
}));
/**
  * 
  * @author Jasin 
  */
function BrandFee() {

    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [brandFeeId, setbrandFeeId] = useState('');
    const classes = useStyles();

    const addUpdateBrandFeeModal = (id='') => {
        setbrandFeeId(id ?? '');
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Can I="add_update_brand_fee" ability={ability()}>
                        <Button variant="outlined" startIcon={<AddIcon />} className={classes.addBrandFeeButton} onClick={() => addUpdateBrandFeeModal()}>
                            Add Brand Fee
                        </Button>
                    </Can>

                    <AddUpdateBrandFee show={showAddUpdateModal} closeModal={addUpdateBrandFeeModal} id={brandFeeId}/>
                </Grid>
                <Grid item xs={12}>
                    <BrandFeeDatatable addUpdateBrandFeeModal = {addUpdateBrandFeeModal} />
                </Grid>
            </Grid>
        </div >
    )
}

export { BrandFee }