import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BusinessAction } from '../../redux/actions'


function BrandCoupons({ couponIssued, getDailyCouponsIssued, getDailyCouponCounts, brandId, loading, ...props }) {
    useEffect(() => {console.log(brandId, 'brandIdbrandIdbrandId');
        getDailyCouponsIssued({'brandId': brandId});
    }, [])
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Daily Coupons Issued'
        },
        xAxis: {
            categories: (loading ? []  : couponIssued.date),
            crosshair: true,
            min: 0,
            max: 9
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Coupons'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Coupons Issued',
            data: (loading ? []  : couponIssued.count)
        }]
    };
    return (
        <div style={{width:'100%', position:'relative'}}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    couponIssued: state.BusinessReducer.couponIssued,
    loading: state.BusinessReducer.loading,
});

function mapDispatchToProps(dispatch) {
    return {
        getDailyCouponsIssued: (params) => dispatch(BusinessAction.getDailyCouponsIssued(params)),
    }
};


const connectedBrandCoupons= connect(mapStateToProps, mapDispatchToProps)(BrandCoupons);
export { connectedBrandCoupons as BrandCoupons };
