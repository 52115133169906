import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { AddUpdatePermission, PermissionDatatable, PermissionTree } from '.';
import { UpdateStatusModal } from '../../../_components/modal';
import { SnackBarAlert } from '../../../_components/SnackBarAlert/SnackBarAlert';
import { PermissionAction } from '../../../redux/actions';
import ability from '../../../_helpers/permissions/ability';
import { Can } from '../../../_helpers';

const useStyles = makeStyles((theme) => ({
    addPermissionButton: {
        "float" : "right"
    }
}));

/**
  * 
  * @author Jasin 
  */
function Permission({ updatePermissionStatus, user }) {

    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [permissionId, setPermissionId] = useState('');
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });
    const classes = useStyles();

    const addUpdatePermissionModal = (id='') => {
        setPermissionId(id ?? '');
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    const statusUpdateModal = ({id, status, item}) => {
        setStatusData({ id: id, status: status, item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    const updateStatusAction = (id) => {
        setSnackBar({ type: '', message: '' })
        setShowStatusUpdateModal(!showStatusUpdateModal);
        updatePermissionStatus({ 'id': id }).then((response) => {
            if (response.status === 1) {
                setSnackBar({type:'success', message:response.message})
            } else {
                setSnackBar({type:'error', message:response.message})
            }
        });
    }

    return (
        <div>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Can I='add_update_permissions' ability= { ability() } passThrough={(user.role_slug === 'developer') ? true : false}>
                        <Button variant="outlined" startIcon={<AddIcon />} className={classes.addPermissionButton} onClick={() => addUpdatePermissionModal()}>
                            Add Permission
                        </Button>
                    </Can>

                    <AddUpdatePermission show={showAddUpdateModal} closeModal={addUpdatePermissionModal} id={permissionId}/>
                    <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction}  />
                </Grid>

                <Grid container spacing={2} justify="center" style={{marginTop:'10px'}}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ height: "100%", padding: "20px"}}>
                            <PermissionDatatable addUpdatePermissionModal = {addUpdatePermissionModal} statusUpdateModal = {statusUpdateModal}/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: "100%", padding: "20px"}}>
                            <CardHeader
                                title="Permission Hierarchy"
                            />
                            <CardContent sx={{ lineHeight: '2' }}>
                                    <PermissionTree />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updatePermissionStatus: (params) => dispatch(PermissionAction.updatePermissionStatus(params))
    }
};

const connectedPermission = (connect(mapStateToProps, mapDispatchToProps)(Permission));
export { connectedPermission as Permission };