import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { ConfzModal, ConfzModalHeader, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../_components/modal';
import { FormInput, FormSelect } from '../../_components/form';
import { validateForm } from '../../_helpers';
import validateBrandFee from './validateBrandFee';
import { BrandFeeAction } from '../../redux/actions';

const inputs = { brand_fee_id:'', brand_id: '', brand_name: '', coupon_count: '', giveaway_count: '', fee: '', additional_coupon_count: '', additional_fee: '', additional_giveaway_count: '', additional_giveaway_fee: ''};

function AddUpdateBrandFee({ id, show, closeModal, addBrandFee, brandListSelect,getBrandListSelect, loading, getBrandFee}) {
    const [data, setData] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getBrandFee({'id': id}).then((response) => {
                if (response.status === 1) {
                    setData({
                        brand_fee_id: response.data._id,
                        brand_id: response.data.brand_id,
                        brand_name: response.data.brand_name,
                        coupon_count: response.data.coupon_count,
                        giveaway_count: response.data.giveaway_count,
                        fee: response.data.fee,
                        additional_coupon_count: response.data.additional_coupon_count,
                        additional_fee: response.data.additional_fee,
                        additional_giveaway_count: response.data.additional_giveaway_count,
                        additional_giveaway_fee: response.data.additional_giveaway_fee
                    });
                }
                getBrandListSelect({'id': response.data.brand_id});
                setShowLoader(false);
            });
        } else {
            getBrandListSelect();
            setData({ ...inputs });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onHide = () => {
        setData({ ...inputs })
        setErrors({ ...inputs })
        closeModal(false);
    }

    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validateBrandFee(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateBrandFee(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchBrandFeeAction(data);
    }

    const dispatchBrandFeeAction = (data) => {
        let dispatchAction = addBrandFee(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setData({ ...inputs })
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
        <React.Fragment>
            <ConfzModal isModalOpen={show} onHide={onHide}  size="lg" loader={showLoader}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="AddUpdateBrandFee" title={(id) ? 'Update Brand Fee' : 'Add Brand Fee'} onHide={onHide} />
                    <ConfzModalBody>
                    {!showLoader &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormSelect
                                    tabIndex={1}
                                    label='Brand'
                                    name='brand_id'
                                    onChange={handleChange}
                                    value= {{value: data.brand_id, name: data.brand_name}}
                                    error={(action.isSubmitted && (errors.brand_id)) ? (errors.brand_id) : ''}
                                    options = {!loading ? brandListSelect : []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Monthly Coupons'
                                    name='coupon_count'
                                    onChange={handleChange}
                                    value={data.coupon_count}
                                    error={(action.isSubmitted && (errors.coupon_count)) ? (errors.coupon_count) : ''}
                                    maskType={`digit-only`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Monthly Giveaway'
                                    name='giveaway_count'
                                    onChange={handleChange}
                                    value={data.giveaway_count}
                                    error={(action.isSubmitted && (errors.giveaway_count)) ? (errors.giveaway_count) : ''}
                                    maskType={`digit-only`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Monthly Fee ($)'
                                    name='fee'
                                    onChange={handleChange}
                                    value={data.fee}
                                    error={(action.isSubmitted && (errors.fee)) ? (errors.fee) : ''}
                                    maskType={`digit-with-one-dot`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Additional Coupons'
                                    name='additional_coupon_count'
                                    onChange={handleChange}
                                    value={data.additional_coupon_count}
                                    error={(action.isSubmitted && (errors.additional_coupon_count)) ? (errors.additional_coupon_count) : ''}
                                    maskType={`digit-only`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Additional Coupon Fee ($)'
                                    name='additional_fee'
                                    onChange={handleChange}
                                    value={data.additional_fee}
                                    error={(action.isSubmitted && (errors.additional_fee)) ? (errors.additional_fee) : ''}
                                    maskType={`digit-with-one-dot`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Additional Giveaway'
                                    name='additional_giveaway_count'
                                    onChange={handleChange}
                                    value={data.additional_giveaway_count}
                                    error={(action.isSubmitted && (errors.additional_giveaway_count)) ? (errors.additional_giveaway_count) : ''}
                                    maskType={`digit-only`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    tabIndex={1}
                                    label='Additional Giveaway Fee ($)'
                                    name='additional_giveaway_fee'
                                    onChange={handleChange}
                                    value={data.additional_giveaway_fee}
                                    error={(action.isSubmitted && (errors.additional_giveaway_fee)) ? (errors.additional_giveaway_fee) : ''}
                                    maskType={`digit-with-one-dot`}
                                />
                            </Grid>
                        </Grid>
                    }
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                        <ConfzModalFooterSaveBtn tabIndex="3" />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        brandListSelect: state.BrandFeeReducer.brandListSelect,
        loading: state.BrandFeeReducer.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addBrandFee: (params) => dispatch(BrandFeeAction.addBrandFee(params)),
        getBrandListSelect: (params) =>dispatch(BrandFeeAction.getBrandListSelect(params)),
        getBrandFee: (params) => dispatch(BrandFeeAction.getBrandFee(params))
    }
};

const connectedAddUpdateBrandFee = connect(mapStateToProps, mapDispatchToProps)(AddUpdateBrandFee);
export { connectedAddUpdateBrandFee as AddUpdateBrandFee };

