import { ManageUsersServices } from "../services";

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';

export const SET_PAGE = 'SET_PAGE';

export const ManageUsersAction = {
    /**
     * function set get users list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
     */
     getUsersList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return ManageUsersServices.getUsersList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_USERS_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_USERS_LIST_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_USERS_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },

    /**
     * function set add user
     * @param params
     * @return view
     * @author Jasin
     * @created_at 10/12/2021
    */
    addUser: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ManageUsersServices.addUser(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_USER_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_USER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_USER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set add user
     * @param params
     * @return view
     * @author Jasin
     * @created_at 13/12/2021
    */
    getUser: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ManageUsersServices.getUser(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_USER_REQUEST, request: request } }
        function success(request, response) { return { type: GET_USER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_USER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function update user status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 30/12/2021
    */
    updateStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ManageUsersServices.updateStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_USER_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_USER_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_USER_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
}


