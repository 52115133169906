import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { EmailLogsDatatable } from './EmailLogsDatatable';

/**
  * 
  * @author Jasin 
  */
function EmailLogs() {

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EmailLogsDatatable />
                </Grid>
            </Grid>
        </div >
    )
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedEmailLogs = (connect(mapStateToProps, mapDispatchToProps)(EmailLogs));
export { connectedEmailLogs as EmailLogs };