import { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Grid, Box, IconButton, MenuItem, Menu, Button, ListItemIcon, TableCell, TableSortLabel} from '@mui/material';
import { Search, MoreHoriz, Visibility, Verified } from '@mui/icons-material';

import { DataTable } from '../../_components/DataTable/DataTable';
import { BusinessAction } from '../../redux/actions'
import { DataTableLoader } from '../../_components/loaders/DataTableLoader'
import { FormInput, FormSelect } from '../../_components/form';
import moment from 'moment';
import { useHistory } from 'react-router';
import { makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
        '& .MuiList-root': {
            padding: 6,
            minWidth: 100
        },
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiDivider-root': {
            marginTop: 3,
            marginBottom: 3
        }
    }
}));

/**
  * Action for register brand
  * @author Ashwanth 
  */
function Business({brandListSelect, brandListSelectloading}) {
    const [brandId, setBrandId] = useState('');
    const [sort, setSort] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch()
    const classes = useStyles();
    let history = useHistory();

    const {
        loading,
        data,
        errors,
        totalCount
    } = useSelector(state => state.BusinessReducer)

    const [search, setSearch] = useState('')
    const [startSearch, setStartSearch] = useState(false)
    const [filter, setFilter] = useState({ brand_filter : ''});
    const [startFilter, setStartFilter] = useState(false);
    const [clearFilter, setclearFilter] = useState(false);
    const [page, setPage] = useState(0)

    const handleActionClick = (event, value) => {
        setAnchorEl(event.currentTarget);
        setBrandId(value);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    function handleViewClick() {        
        history.push('/view-brand/'+ brandId);
    }

    useEffect(() => {
        let params = {
            search,
            page,
            sort,
            filter
        }
        dispatch(BusinessAction.getBusinessList(params))
    }, [])

    useEffect(() => {
        let params = {
            search,
            page,
            sort,
            filter
        }
        dispatch(BusinessAction.getBusinessList(params))
        setStartSearch(false)
        setStartFilter(false)
        setclearFilter(false)
    }, [startSearch, startFilter, page, sort])


    const [businesses, setBusinesses] = useState([])

    function getDealType(dealTypeAbbr) {
        var dealType = '';
        switch (dealTypeAbbr) {
            case 'slr': {
                dealType = 'Story - Like'
                break;
            } case 'sler': {
                dealType = 'Story - Like - Email'
                break;
            } case 'lr': {
                dealType = 'Like'
                break;
            } case 'ler': {
                dealType = 'Like - Email'
                break;
            } case 'sleg': {
                dealType = 'Story - Like - Email - Giveaway'
                break;
            } case 'leg': {
                dealType = 'Like - Email - Giveaway'
                break;
            } default: {
                dealType = '';
                break;
            }
        }
        return dealType;
    }

    function handleStartSearch(e) {
        setPage(0)
        setStartSearch(true)
    }

    function handleFilterChange(e) {
        const { name, value } = e;
        filter[name] = value;
        setFilter({ ...filter });
    }

    function handleStartFilter(e) {
        setPage(0)
        setStartFilter(true)
    }

    function handleFilterReset(e) {
        setFilter({ brand_filter : ''});
        setclearFilter(true)
        setPage(0)
        setStartFilter(true)
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (rowIndex, dataIndex) => {
                    return ((dataIndex.tableState.rowsPerPage * dataIndex.tableState.page) + dataIndex.rowIndex + 1) ;
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: false,
            }
        },
        {
            name: "company_name",
            label: "Company",
            options: {
                sort: false,
            }
        },
        {
            name: "deal_details",
            label: "Deal Available",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, rowIndex, dataIndex, data) => {
                    var dealType = '';
                    if (value) {
                        dealType = getDealType(value.deal_type);
                    }
                    
                    return (
                        <>
                            {!value && <span className="badge badge-danger" style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>NO</span> }
                            <br />
                            {dealType}{value ? <Verified sx={{ color: green[500] }} fontSize="small" /> : ''}
                        </>
                    );
                },
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            }
        },
        {
            name: "offer_instagram_handle",
            label: "Offer Instagram handle",
            options: {
                sort: false,
            }
        },
        {
            name: "brand_domain",
            label: "Subdomain",
            options: {
                sort: false,
                customBodyRender: value => <a href={(value) ? (`https://${value}.${process.env.REACT_APP_DOMAIN}`) : ('-')} target="_blank" rel="noreferrer"> {(value) ? (`https://${value}.${process.env.REACT_APP_DOMAIN}`) : ('-')}</a>
            },
        },
        {
            name: "created_at",
            label: "Created on",
            options: {
                // sort: true,
                customBodyRender: value => moment(new Date(value)).format('MM/DD/YYYY'),
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            },

        },
        {
            name: "_id",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <IconButton
                                aria-label="more"
                                id="action-button"
                                aria-controls="action-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={event => handleActionClick(event, value)}
                            >
                                <MoreHoriz />
                            </IconButton>

                            <Menu 
                                id="action-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleActionClose}
                                MenuListProps={{
                                'aria-labelledby': 'action-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    className: classes.menuPaper,
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem 
                                    onClick={handleViewClick}
                                >
                                    <ListItemIcon>
                                        <Visibility fontSize="small" />
                                    </ListItemIcon>
                                    View

                                </MenuItem>
                            </Menu>
                        </>
                    );
                }
            },

        },
    ];


    function changePage(page) {
        setPage(page)
    }

    useEffect(() => {
        if (!loading && data.length) setBusinesses(data)
    }, [loading])

    function handleChange(e) {
        const { name, value } = e;
        data[name] = value;
        setSearch(value);
    }

    const options = {
        serverSide: true,
        filter: false,
        resonsive: 'stacked',
        print: false, //PDF
        search: false,
        download: false, //CSV
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPageOptions: [],
        rowsPerPage: 10,
        sortOrder: {
            name: sort.name,
            direction: sort.direction
        },
        page: page,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case "changePage":
                    changePage(tableState.page);
                    break;
                case 'sort':
                    setSort(tableState.sortOrder)
                    break;
            }
        }
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={5} container spacing={1} >
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <FormSelect
                                tabIndex={1}
                                label='Brand'
                                name='brand_filter'
                                onChange={handleFilterChange}
                                value= {{value: filter.brand_filter, name: ""}}
                                clearFilter={clearFilter}
                                options = {!brandListSelectloading ? brandListSelect : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} container spacing={1}>
                        <Grid item xs={6} md={4}>
                            <Button fullWidth variant="outlined" onClick={() => handleStartFilter() } style={{ 'height': '4em' }}>
                                    Filter
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Button fullWidth variant="contained" onClick={() => handleFilterReset() } style={{ 'height': '4em' }}>
                                    Reset
                            </Button>
                        </Grid>                 
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} container spacing={2}>
                    <Grid item xs={8}>
                        <FormInput
                            tabIndex={1}
                            label='Search'
                            name='search'
                            value={search}
                            // error={action.isSubmitted && errors.email ? errors.email : ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="outlined" onClick={() => handleStartSearch() } style={{ 'height': '4em' }}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <br />
            <br />

            {loading ? (
                <DataTableLoader />
            ) : (
                <DataTable
                    title={"Brands"}
                    data={businesses}
                    columns={columns}
                    options={options}
                />
            )}
        </div >
    )
}

function mapStateToProps(state) {
    return {
        brandListSelect: state.BusinessReducer.brandListSelect,
        brandListSelectloading: state.BusinessReducer.brandListSelectloading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBrandListSelect: dispatch(BusinessAction.getBrandListSelect()),
    }
};

const connectedBusiness = connect(mapStateToProps, mapDispatchToProps)(Business);
export { connectedBusiness as Business };