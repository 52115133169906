import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid, IconButton, MenuItem, Menu, Button, ListItemIcon, TableCell, TableSortLabel} from '@mui/material';
import { Check, Close, ModeEdit, MoreHoriz } from '@mui/icons-material';

import { DataTable } from '../../../_components/DataTable/DataTable';
import { DataTableLoader } from '../../../_components/loaders/DataTableLoader'
import { FormInput } from '../../../_components/form';
import { makeStyles } from '@mui/styles';
import { PermissionAction } from '../../../redux/actions';
import { Can } from '../../../_helpers';
import ability from '../../../_helpers/permissions/ability';

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
        '& .MuiList-root': {
            padding: 6,
            minWidth: 100
        },
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiDivider-root': {
            marginTop: 3,
            marginBottom: 3
        }
    }
}));
function PermissionDatatable({ getPermissionList, addUpdatePermissionModal, permission, statusUpdateModal, loading, user, ...props}) {
    const [permissionId, setPermissionId] = useState('');
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState('');
    const [startSearch, setStartSearch] = useState(false)
    const [page, setPage] = useState(0);
    const [permissionList, setPermissionList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const open = Boolean(anchorEl);
    const classes = useStyles();

    useEffect(() => {
        let params = {
            search,
            page,
            sort,
        }
        getPermissionList(params).then((response) => {
            setPermissionList(response.data && response.data.length>0 ? response.data : []);
            setTotalCount(response.totalCount)
            setStartSearch(false)
        });

    }, [startSearch, page, permission, sort])

    const handleActionClick = (event, value, status) => {
        setAnchorEl(event.currentTarget);
        setPermissionId(value);
        setStatusData({ id: value, status: status, item: '' });
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    function handleEditClick() {      
        setAnchorEl(null);
        setTimeout(() => {
            addUpdatePermissionModal(permissionId)
        }, 100);
    }

    function handleStatusChangeClick() {      
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    function changePage(page) {
        setPage(page)
    }

    function handleChange(e) {
        const { name, value } = e;
        setSearch(value);
    }

    function handleStartSearch(e) {
        setPage(0)
        setStartSearch(true)
    }
    
    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (rowIndex, dataIndex) => {
                    return ((dataIndex.tableState.rowsPerPage * dataIndex.tableState.page) + dataIndex.rowIndex + 1) ;
                }
            }
        },
        {
            name: "permission",
            label: "Permission",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "parent",
            label: "Parent",
            options: {
                filter: false,
                sort: true,
                customHeadRender: ({index, name,...column}, sortColumn) => {
                    return (
                      <TableCell onClick={() => sortColumn(index)} key={index}>
                        <TableSortLabel active={sort.name === name && sort.direction ? true : false} direction={sort.name === name && sort.direction ? sort.direction : "asc"}> {column.label} </TableSortLabel>
                      </TableCell>
                    );
                },
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                    <>
                        <span className={"badge " + (value === 'active' ? 'badge-success' : 'badge-danger') } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{(value === 'active' ? 'Active' : 'Inactive')}</span> 
                        <br />
                    </>
                    )
                }
            }
        },
        {
            name: "_id",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, dataIndex) => {
                    var permissionStatus = permissionList[dataIndex.rowIndex].status === 'active' ? 'active' : 'inactive';
                    return (
                        <>
                            <IconButton
                                aria-label="more"
                                id="action-button"
                                aria-controls="action-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={event => handleActionClick(event, value, permissionStatus)}
                            >
                                <MoreHoriz />
                            </IconButton>

                            <Menu 
                                id="action-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleActionClose}
                                MenuListProps={{
                                'aria-labelledby': 'action-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    className: classes.menuPaper,
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Can I='add_update_permissions' ability= { ability() } passThrough={(user.role_slug === 'developer') ? true : false}>
                                    <MenuItem 
                                        onClick={handleEditClick}
                                    >
                                        <ListItemIcon>
                                            <ModeEdit fontSize="small" />
                                        </ListItemIcon>
                                        Edit

                                    </MenuItem>
                                </Can>
                                <Can I='update_permission_status' ability= { ability() } passThrough={(user.role_slug === 'developer') ? true : false}></Can>
                                <MenuItem
                                    onClick={handleStatusChangeClick}
                                >
                                    <ListItemIcon>
                                    {statusData.status === 'active' ? <Close fontSize="small" /> :  <Check fontSize="small" /> }
                                   
                                    </ListItemIcon>
                                    {statusData.status === 'active' ? 'Inactivate' : 'Activate'}

                                </MenuItem>
                            </Menu>
                        </>
                    );
                },
                setCellProps: () => ({ align: 'center' }),
                setCellHeaderProps: () => ({ align: 'center' })
            },

        },
    ];

    const options = {
        serverSide: true,
        filter: false,
        resonsive: 'stacked',
        print: false, //PDF
        search: false,
        download: false, //CSV
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPageOptions: [],
        rowsPerPage: 10,
        page: page,
        count: totalCount,
        sortOrder: {
            name: sort.name,
            direction: sort.direction
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case "changePage":
                    changePage(tableState.page);
                    break;
                    case 'sort':
                    setSort(tableState.sortOrder)
                    break;
            }
        }
    };
    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={6} container spacing={2}>
                    <Grid item xs={8}>
                        <FormInput
                            tabIndex={1}
                            label='Search'
                            name='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="outlined" onClick={() => handleStartSearch() } style={{ 'height': '4em' }}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <br />
        {loading ? (
            <DataTableLoader />
        ) : (
            <DataTable
                title={"Permissions"}
                data={permissionList}
                columns={columns}
                options={options}
            />
        )}
    </div >
    )
}



function mapStateToProps(state) {
    return {
        permission: state.PermissionReducer.permission,
        user: state.UserReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPermissionList: (params) => dispatch(PermissionAction.getPermissionList(params)),
    }
};

const connectedPermissionDatatable = connect(mapStateToProps, mapDispatchToProps)(PermissionDatatable);
export { connectedPermissionDatatable as PermissionDatatable };

