import { apiServices } from "../../_helpers";
import queryString from 'query-string';

export const PermissionServices = {
    /**
     * function get permission
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
     */
     getPermissionList: (params) => {
        return apiServices.get(`/permission/get-permission-list`, { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
     * function get permission list for select
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
     */
    getPermissionListSelect: () => {
        return apiServices.get(`/permission/get-permission-list-select`)
            .then((response) => {
                return response;
            })
    },

    /**
    * function add permission
    * @param params
    * @return view
    * @author Jasin
    * @created_at 21/01/2022
    */
    addPermission: (params) => {
        return apiServices.post('/permission/add-permission', params)
            .then((response) => {
                return response;
            })
    },

    /**
    * function add permission
    * @param params
    * @return view
    * @author Jasin
    * @created_at 21/01/2022
    */
    updatePermission: (params) => {
        return apiServices.post('/permission/update-permission', params)
            .then((response) => {
                return response;
            })
    },
    
    /**
    * function add permission
    * @param params
    * @return view
    * @author Jasin
    * @created_at 21/01/2022
    */
    getPermission: (params) => {
        let queryParam = {};
        queryParam.permission_id = params.id;
        return apiServices.get(`/permission/get-permission?${queryString.stringify(queryParam)}`, params)
            .then((response) => {
                return response;
            }) 
    },
    
    /**
     * function get permission hierarchy
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/01/2022
    */
    getPermissionHierarchy: (params) => {
        return apiServices.get(`/permission/get-permission-heirarhy`, { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
     * function update permission status
     * @param params
     * @return view
     * @author Jasin
     * @created_at 24/01/2022
    */
    updatePermissionStatus: (params) => {
        return apiServices.put('/permission/update-permission-status', params)
            .then((response) => {
                return response;
            })
    },
    
    
}

