import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useStyles } from '.'

function DataTable({ ...props }) {
    const classes = useStyles()

    return (
        <div>
            <MUIDataTable {...props} />
        </div>
    )
}

export { DataTable }
