import { BrandFeeServices } from "../services";

export const ADD_BRAND_FEE_REQUEST = 'ADD_BRAND_FEE_REQUEST';
export const ADD_BRAND_FEE_SUCCESS = 'ADD_BRAND_FEE_SUCCESS';
export const ADD_BRAND_FEE_FAILURE = 'ADD_BRAND_FEE_FAILURE';

export const GET_BRAND_LIST_SELECT_REQUEST = 'GET_BRAND_LIST_SELECT_REQUEST';
export const GET_BRAND_LIST_SELECT_SUCCESS = 'GET_BRAND_LIST_SELECT_SUCCESS';
export const GET_BRAND_LIST_SELECT_FAILURE = 'GET_BRAND_LIST_SELECT_FAILURE';

export const GET_BRAND_FEE_LIST_REQUEST = 'GET_BRAND_FEE_LIST_REQUEST';
export const GET_BRAND_FEE_LIST_SUCCESS = 'GET_BRAND_FEE_LIST_SUCCESS';
export const GET_BRAND_FEE_LIST_FAILURE = 'GET_BRAND_FEE_LIST_FAILURE';

export const GET_BRAND_FEE_REQUEST = 'GET_BRAND_FEE_REQUEST';
export const GET_BRAND_FEE_SUCCESS = 'GET_BRAND_FEE_SUCCESS';
export const GET_BRAND_FEE_FAILURE = 'GET_BRAND_FEE_FAILURE';

// export const SET_PAGE = 'SET_PAGE';

export const BrandFeeAction = {

    /**
     * function set add brand fee
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/12/2021
    */
    addBrandFee: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandFeeServices.addBrandFee(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_BRAND_FEE_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_BRAND_FEE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_BRAND_FEE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function get brand list for select
     * @param params
     * @return view
     * @author Jasin
     * @created_at 21/12/2021
    */
    getBrandListSelect: (params) => {
        return dispatch => {
            dispatch(request());
            return BrandFeeServices.getBrandListSelect(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_BRAND_LIST_SELECT_REQUEST, request } }
        function success(request, response) { return { type: GET_BRAND_LIST_SELECT_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_BRAND_LIST_SELECT_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * function set get brand fee list
     * @param params
     * @return view
     * @author Jasin
     * @created_at 22/12/2021
     */
    getBrandFeeList: (params) => {
        return dispatch => {
            dispatch(request(params))
            return BrandFeeServices.getBrandFeeList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        }


        function request(request) { return { type: GET_BRAND_FEE_LIST_REQUEST, request } }
        function success(request, response) { return { type: GET_BRAND_FEE_LIST_SUCCESS, request, message: response.message, data: response.data, status: response.status, status_code: response.status.code } }
        function failure(request, error) { return { type: GET_BRAND_FEE_LIST_FAILURE, request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } }
    },



    /**
     * function get brand fee
     * @param params
     * @return view
     * @author Jasin
     * @created_at 22/12/2021
    */
    getBrandFee: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandFeeServices.getBrandFee(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_BRAND_FEE_REQUEST, request: request } }
        function success(request, response) { return { type: GET_BRAND_FEE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_BRAND_FEE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    }
}


