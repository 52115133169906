import { 
    GET_EMAIL_LOGS_LIST_REQUEST, GET_EMAIL_LOGS_LIST_SUCCESS, GET_EMAIL_LOGS_LIST_FAILURE,
    GET_EMAIL_LOGS_DETAILS_REQUEST, GET_EMAIL_LOGS_DETAILS_SUCCESS, GET_EMAIL_LOGS_DETAILS_FAILURE,
    EMAIL_LOG_RESEND_MAIL_REQUEST, EMAIL_LOG_RESEND_MAIL_SUCCESS, EMAIL_LOG_RESEND_MAIL_FAILURE,

} from '../actions'

const initialState = { 
    email_logs_loading: false, email_logs: [], email_logs_errors: {},
    email_logs_details_loading: false, email_logs_details: {}, email_logs_details_errors: {},
    email_logs_resend_loading: false, email_logs_resend: {}, email_logs_resend_errors: {},

}

export function EmailLogsReducer(state = initialState, action) {
    switch (action.type) {
        //  begin::Get email logs list redux 
        case GET_EMAIL_LOGS_LIST_REQUEST: {
            return {
                ...state,
                email_logs_loading: true,
                email_logs_errors: false,
            }
        }
        case GET_EMAIL_LOGS_LIST_SUCCESS: {
            return {
                ...state,
                email_logs_loading: false,
                email_logs: action.data,
            }
        }
        case GET_EMAIL_LOGS_LIST_FAILURE: {
            return {
                ...state,
                email_logs_loading: false,
                email_logs_errors: action.errors,
            }
        }
        // begin::Get email logs list redux 

        //  begin::Get busineee list redux 
        case GET_EMAIL_LOGS_DETAILS_REQUEST: {
            return {
                ...state,
                email_logs_details_loading: true,
                email_logs_details_errors: {},

            }
        }
        case GET_EMAIL_LOGS_DETAILS_SUCCESS: {
            return {
                ...state,
                email_logs_details_loading: false,
                email_logs_details: action.data,
            }
        }

        case GET_EMAIL_LOGS_DETAILS_FAILURE: {
            return {
                ...state,
                email_logs_details_loading: false,
                email_logs_details_errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        //  begin::Get busineee list redux 
        case EMAIL_LOG_RESEND_MAIL_REQUEST: {
            return {
                ...state,
                email_logs_resend_loading: true,
                email_logs_resend_errors: {},

            }
        }
        case EMAIL_LOG_RESEND_MAIL_SUCCESS: {

            let newListArray = state.email_logs; //making a new array
            let newDetails = state.email_logs_details; //making a new array

            if (newListArray.email_logs) {
                const brandIndex = newListArray.email_logs.findIndex(brands => brands._id === action.data._id);
                if (brandIndex !== -1) {
                    newListArray.email_logs[brandIndex].status = action.data.status;
                    newListArray.email_logs[brandIndex].response = action.response;
                } 
            }

            if (newDetails && (newDetails._id === action.data._id)) {
                newDetails.status = action.data.status;
                newDetails.response = action.data.response;
            }
            return {
                ...state,
                email_logs_resend_loading: false,
                email_logs_resend: action.data,
            }
        }

        case EMAIL_LOG_RESEND_MAIL_FAILURE: {
            return {
                ...state,
                email_logs_resend_loading: false,
                email_logs_resend_errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        default:
            return state
    }

}
