import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { APP_NAME, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormInput, FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';

import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from '.'

import { RegisterAction } from '../../../redux/actions';


// Initialize form input values to null
const inputs = { first_name: '', last_name: '', email: '', password: '', confirm_password: '' };

/**
 * function to validate the registration form inputs
 * @param {string} name Input name
 * @param {string} value Input value
 * @param {object} [data={}] All input values stored in the state
 * @author Akshay N
 */
const validate = (name, value, data = {}) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'first_name': {
            errors.first_name = '';
            if (value === '')
                errors.first_name = 'Enter first name';
            break;
        } case 'last_name': {
            errors.last_name = '';
            if (value === '')
                errors.last_name = 'Enter last name';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
            if (value === '')
                errors.email = 'Enter an email address';
            break;
        } case 'password': {
            const confirmPassword = data.confirm_password ?? '';
            errors.password = value.length < 6 ? 'Password must be 6 characters long' : '';
            if (confirmPassword !== '' && confirmPassword === value)
                errors.confirm_password = '';
            if (value === '')
                errors.password = 'Enter password';
            break;
        } case 'confirm_password': {
            const password = data.password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long' : '';
            if (password !== '' && password !== value)
                errors.confirm_password = 'Confirm password should match with the password';
            if (value === '')
                errors.confirm_password = 'Re-enter your password';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}

function Register({ isLoading, register, registrationErrors, props }) {

    const classes = useStyles();

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        setErrors({ ...registrationErrors });
    }, [registrationErrors])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = e;
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @author Akshay N
     */
    async function dispatchRegisterAction() {
        const registered = await register(data);
        if (registered && registered.status === 1) {
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : '/');
            history.push(redirectPath);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('register').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                tabIndex={1}
                                label='First name'
                                name='first_name'
                                value={data.first_name}
                                error={action.isSubmitted && errors.first_name ? errors.first_name : ''}
                                onChange={handleChange}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormInput
                                tabIndex={2}
                                label='Last name'
                                name='last_name'
                                value={data.last_name}
                                error={action.isSubmitted && errors.last_name ? errors.last_name : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput
                                tabIndex={3}
                                label='Email'
                                name='email'
                                value={data.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormPassword
                                tabIndex={4}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormPassword
                                tabIndex={5}
                                label='Re-enter password'
                                name='confirm_password'
                                value={data.confirm_password}
                                error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ paddingTop: `0` }}>
                            <Typography variant="caption" color="initial" style={{ color: `rgba(0, 0, 0, 0.6)` }}>Use 6 or more characters with a mix of letters, numbers and symbols</Typography>
                        </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.actionBox}>
                        <RouteLink to={PreAuthRoutes('login').path} label={`${PreAuthRoutes('login').name} insted`} tabIndex={9} />
                        <SubmitButton tabIndex={8} label={'Next'} fullWidth loading={isLoading} />
                    </Box>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}


const mapStateToProps = (state) => ({
    isLoading: state.RegisterReducer.loading,
    registrationErrors: state.RegisterReducer.errors,
})


function mapDispatchToProps(dispatch, ownProps) {
    return {
        register: (params) => dispatch(RegisterAction.register(params))
    }
};


const connectedRegister = connect(mapStateToProps, mapDispatchToProps)(Register);
export { connectedRegister as Register };


