import React from 'react';
import './initial-loader.scss';

function InitialLoader({ loader }) {

    if (!loader) {
        return '';
    }
    return (
        <div className={`initial-loader`}>
            <div className="initial-loader-inner text-center" >
                <img className="blink-image" src={`${process.env.PUBLIC_URL}/img/confyans-logo.svg`} alt="Initial loader" />
            </div>
        </div>
    )
}

export { InitialLoader };
