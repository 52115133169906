import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { AddBox, CheckBox, CheckBoxOutlineBlank, ChevronRight, ExpandMore, Folder, FolderOpen, IndeterminateCheckBox, InsertDriveFile } from '@mui/icons-material';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { PermissionAction } from '../../../redux/actions';

function PermissionTree({ getPermissionHierarchy, permission }) {
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    function getNodeIds(data) {
        let ids = [];
      
        data?.forEach(({ value, children }) => {
            ids = [...ids, value, ...getNodeIds(children)];
        });
      
        return ids;
    }

    useEffect(() => {
        getPermissionHierarchy({show_checkbox : false}).then((response) => {
            setData(response.data ?? []);
            setExpanded(getNodeIds(response.data ?? []))
        });
    }, [permission])

    return (
        <>
            { data.length>0 &&<CheckboxTree
                nodes={data}
                checked={checked}
                expanded={expanded}
                onCheck={ (checked) => setChecked(checked) }
                onExpand={ (expanded) => setExpanded(expanded) }

                icons={{
                    check: <CheckBox fontSize="inherit" />,
                    uncheck: <CheckBoxOutlineBlank fontSize="inherit" />,
                    halfCheck: <CheckBox fontSize="inherit"/>,
                    expandClose: <ChevronRight fontSize="inherit" />,
                    expandOpen: <ExpandMore fontSize="inherit" />,
                    expandAll: <AddBox fontSize="inherit" />,
                    collapseAll: <IndeterminateCheckBox fontSize="inherit" />,
                    parentClose: <Folder fontSize="inherit" />,
                    parentOpen: <FolderOpen fontSize="inherit" />,
                    leaf: <InsertDriveFile fontSize="inherit" />
                }}
            /> }
            { data.length===0 && 'Sorry, no permissions found' }
        </>
    )
}



function mapStateToProps(state) {
    return {
        permission: state.PermissionReducer.permission,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPermissionHierarchy : (params) => dispatch(PermissionAction.getPermissionHierarchy(params)),
    }
};

const connectedPermissionTree = connect(mapStateToProps, mapDispatchToProps)(PermissionTree);
export { connectedPermissionTree as PermissionTree };

