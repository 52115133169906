import React from 'react'
import { Box, Typography } from '@mui/material';
import { ShortLogo } from '../../../_components/logo/short-logo';
import { useStyles } from '.';
import { useLocation } from 'react-router';
import { PreAuthRoutes } from '../../../routes';

function Header() {

    const classes = useStyles();

    const location = useLocation();

    return (
        <Box className={classes.preSignInWrapperHeader} >
            <Box className={classes.preSignInWrapperHeaderLogo} >
                <ShortLogo width="150" height="150" />
            </Box>

            {(location.pathname === PreAuthRoutes('login').path) && (
                <React.Fragment>
                    <Typography variant="h6" component="div" align="center">{PreAuthRoutes('login').name}</Typography>
                    {/*<Typography variant="body1" component="div" align="center">Continue to <span className={`${classes.appName}`}>Confz</span></Typography>*/}
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('login_password').path) && (
                <React.Fragment>
                    <Typography variant="h6" component="div" align="center">{PreAuthRoutes('login_password').name}</Typography>
                    {/*<Typography variant="body1" component="div" align="center">Continue to <span className={`${classes.appName}`}>Confz</span></Typography>*/}
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('register').path) && (
                <React.Fragment>
                    <Typography variant="h6" component="div" align="center">{PreAuthRoutes('register').name}</Typography>
                    <Typography variant="body1" component="div" align="center">Lets grow your business together.</Typography>
                </React.Fragment>
            )}

            {(location.pathname === PreAuthRoutes('forgot_password').path) && (
                <React.Fragment>
                    <Typography variant="h6" component="div" align="center">Find your <span className={`${classes.appName}`}>Confz</span> account</Typography>
                    <Typography variant="body1" component="div" align="center" paragraph>Enter your email address to reset password.</Typography>
                </React.Fragment>
            )}
        </Box>
    )
}

export { Header };
