import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import { APP_NAME, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { FormInput } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';

import { Box, Grid } from '@mui/material';
import { useStyles } from '.'

function ForgotPassword({ props }) {

    const classes = useStyles();

    const inputs = { email: '', password: '', remember_me: false };

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = e;
        console.log(e);
        setErrors({ ...errors, ...(validate(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
    }


    const validate = (name, value) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = { email: '', password: '' };
        switch (name) {
            case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
                if (value === '')
                    errors.email = 'Enter an email address';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('forgot_password').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormInput
                                tabIndex={1}
                                label='Email'
                                name='email'
                                value={data.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                                autoFocus={true}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.actionBox}>
                        <RouteLink to={PreAuthRoutes('login').path} label={PreAuthRoutes('login').name} />
                        <SubmitButton label={'Next'} fullWidth />
                    </Box>
                </form>

            </PreSignIn>
        </React.Fragment>
    );
}

export { ForgotPassword };
