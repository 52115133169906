import { 
    GET_BUSINESS_LIST_REQUEST, GET_BUSINESS_LIST_SUCCESS, GET_BUSINESS_LIST_FAILURE,
    GET_BUSINESS_DETAILS_REQUEST, GET_BUSINESS_DETAILS_SUCCESS, GET_BUSINESS_DETAILS_FAILURE,
    GET_BUSINESS_DAILY_COUPONS_REQUEST, GET_BUSINESS_DAILY_COUPONS_SUCCESS, GET_BUSINESS_DAILY_COUPONS_FAILURE,
    GET_BUSINESS_COUPON_COUNTS_REQUEST, GET_BUSINESS_COUPON_COUNTS_SUCCESS, GET_BUSINESS_COUPON_COUNTS_FAILURE,
    BRAND_LIST_SELECT_REQUEST, BRAND_LIST_SELECT_SUCCESS, BRAND_LIST_SELECT_FAILURE
 } from '../actions'

const initialState = { loading: false, request: {}, data: [], totalCount: 0, errors: {}, businessData: [], couponIssued: {}, brandListSelect:[{}], brandListSelectloading:false }

export function BusinessReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get busineee list redux 
        case GET_BUSINESS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,

            }
        }
        case GET_BUSINESS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.data,
                totalCount: action.totalCount
            }
        }

        case GET_BUSINESS_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        //  begin::Get busineee list redux 
        case GET_BUSINESS_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,

            }
        }
        case GET_BUSINESS_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                businessData: action.data,
                totalCount: action.totalCount
            }
        }

        case GET_BUSINESS_DETAILS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors
            }
        }
        // begin::Get busineee details redux 

        //  begin::Get daily coupons redux 
        case GET_BUSINESS_DAILY_COUPONS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,

            }
        }
        case GET_BUSINESS_DAILY_COUPONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                couponIssued: action.data
            }
        }

        case GET_BUSINESS_DAILY_COUPONS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors
            }
        }
        // begin::Get daily coupons redux 

        //  begin::Get daily coupons redux 
        case GET_BUSINESS_COUPON_COUNTS_REQUEST: {
            return {
                ...state,
                loading: true,
                request: action.request,

            }
        }
        case GET_BUSINESS_COUPON_COUNTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                couponCount: action.data,
            }
        }

        case GET_BUSINESS_COUPON_COUNTS_FAILURE: {
            return {
                ...state,
                loading: false,
                errors: action.errors
            }
        }
        // begin::Get daily coupons redux 

        //  begin::Get business list select redux 
        case BRAND_LIST_SELECT_REQUEST: {
            return {
                ...state,
                brandListSelectloading: true,
                request: action.request,
            }
        }
        case BRAND_LIST_SELECT_SUCCESS: {
            return {
                ...state,
                brandListSelectloading: false,
                brandListSelect: action.data,
            }
        }
        case BRAND_LIST_SELECT_FAILURE: {
            return {
                ...state,
                brandListSelectloading: false,
                errors: action.errors,
            }
        }
        // begin::Get business list select redux 
        default:
            return state
    }

}
