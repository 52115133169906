const validateChangePassword = (name, value, data) => {
    const errors = {};
    switch (name) {
        case 'current_password': {
            errors.current_password = '';
            if (value === '')
                errors.current_password = 'Enter current password';
            break;
        } case 'new_password': {
            const confirmPassword = data.confirm_password ?? '';
            errors.new_password = value.length < 6 ? 'Password must be 6 characters long' : '';
            if (confirmPassword !== '' && confirmPassword === value)
                errors.confirm_password = '';
            if (value === '')
                errors.new_password = 'Enter new password';
            break;
        } case 'confirm_password': {
            const password = data.new_password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long' : '';
            if (password !== '' && password !== value)
                errors.confirm_password = 'Confirm password should match with the password';
            if (value === '')
                errors.confirm_password = 'Enter confirm password';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateChangePassword;