import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Card, CardContent, Typography, Box, CardHeader, Button } from '@mui/material';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { EmailLogsAction } from '../../redux/actions';
import { makeStyles } from '@mui/styles';
import { Send } from '@mui/icons-material';
import { SnackBarAlert } from '../../_components/SnackBarAlert/SnackBarAlert';

const useStyles = makeStyles((theme) => ({
    resendMailButton: {
        "float" : "right"
    }
}));

function EmailLogsDetails({ getEmailLogsDetails, emailLogsDetails, loading, emailLogResendMail, ...props }) {

    let { search } = useLocation();
    const classes = useStyles();

    const params = new URLSearchParams(search);
    const logId = params.get('logId'); // plan id from url
    const [snackBar, setSnackBar] = useState({ type: '', message: '' });

    useEffect(() => {
        getEmailLogsDetails({'emailLogId': logId});
    }, [logId])

    const resendMail = () => {
        setSnackBar({ type: '', message: '' })
        let dispatchAction = emailLogResendMail( { logId: logId });

        dispatchAction.then((response) => {
            if (response.status !== 1) {
                setSnackBar({type:'error', message:response.message})
            } else {
                setSnackBar({type:'success', message:response.message})
            }
        });
    }

    return (
        <React.Fragment>
            { snackBar.message && <SnackBarAlert message={snackBar.message} type={snackBar.type}/> }
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} >
                    <Button disabled={emailLogsDetails.status=== 'failed' ? false : true } variant="outlined" startIcon={<Send />} className={classes.resendMailButton}  onClick={() => resendMail()}>
                        Resend Mail
                    </Button>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{ minWidth: 275, marginBottom: '10px' }}>
                        <CardContent>
                            { !loading && Object.keys(emailLogsDetails).length > 0 && <Grid container spacing={2} >
                                <Grid item xs={6} md container spacing={4}>
                                    { emailLogsDetails.brand && <>
                                        <Grid item xs={5} md={5}>
                                            <Typography variant="subtitle1">Brand</Typography>
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <Typography variant="subtitle1">{emailLogsDetails.brand.company_name}</Typography>
                                        </Grid> 
                                    </>}
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Receiver</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{emailLogsDetails.receiver}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Mail Type</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{emailLogsDetails.mail_type}</Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Status</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <span className={"badge " + (emailLogsDetails.status === 'success' ? 'badge-success' : 'badge-danger') } style={{minWidth:'50px',padding:'5px 0px 5px 0px' , marginBottom: '5px'}}>{(emailLogsDetails.status === 'success' ? 'Success' : 'Failed')}</span>
                                    </Grid>
                                    { emailLogsDetails.status === 'failed' && <>
                                        <Grid item xs={5} md={5}>
                                            <Typography variant="subtitle1">Failure Type</Typography>
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <Typography variant="subtitle1">{emailLogsDetails.response.type}</Typography>
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <Typography variant="subtitle1">Failure Message</Typography>
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <Typography variant="subtitle1">{emailLogsDetails.response.message}</Typography>
                                        </Grid>
                                        { emailLogsDetails.response.param === 'failed' && <>
                                            <Grid item xs={5} md={5}>
                                                <Typography variant="subtitle1">Failure Param</Typography>
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <Typography variant="subtitle1">{emailLogsDetails.response.param}</Typography>
                                            </Grid> 
                                        </> }
                                    </> }
                                    <Grid item xs={5} md={5}>
                                        <Typography variant="subtitle1">Created At</Typography>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <Typography variant="subtitle1">{moment(new Date(emailLogsDetails.created_at)).format('MM/DD/YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Card sx={{ minWidth: 275, marginBottom: '10px', padding: 2 }}>
                        <CardHeader
                            title="API Request"
                        />
                        <CardContent>
                            { !loading && Object.keys(emailLogsDetails).length > 0 && <Grid container spacing={2} >
                            <Box sx={{ whiteSpace: 'normal' }} >
                                <pre>{emailLogsDetails.email_data}</pre>
                            </Box>
                            </Grid> }
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginBottom: '10px', padding: 2 }}>
                        <CardHeader
                            title="Response"
                        />
                        <CardContent>
                            { !loading && Object.keys(emailLogsDetails).length > 0 && <Grid container spacing={2} >
                            <Box sx={{ whiteSpace: 'normal' }} >
                                <pre>{JSON.stringify(emailLogsDetails.response, null, 2)}</pre>
                            </Box>
                            </Grid> }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    emailLogsDetails: state.EmailLogsReducer.email_logs_details,
    loading: state.EmailLogsReducer.email_logs_details_loading,
})


function mapDispatchToProps(dispatch) {
    return {
        getEmailLogsDetails: (params) => dispatch(EmailLogsAction.getEmailLogsDetails(params)),
        emailLogResendMail: (params) => dispatch(EmailLogsAction.emailLogResendMail(params)),
    }
};


const connectedEmailLogsDetails= connect(mapStateToProps, mapDispatchToProps)(EmailLogsDetails);
export { connectedEmailLogsDetails as EmailLogsDetails };
