import { Box, Skeleton } from '@mui/material'

function DataTableLoader() {
    return (
        <div>
            <Box>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
            </Box>
        </div>
    )
}

export { DataTableLoader }

