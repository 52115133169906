import { apiServices } from "../../_helpers";

export const BusinessServices = {
    /**
       * Service for register brand
       * @author Ashwanth
       */
    getBusinessList: (params) => {
        return apiServices.get(`/admin/getAllBusiness`, { params: params })
            .then((response) => {
                return response;
            })
    },

    getBusinessDetails: (params) => {
        return apiServices.get(`/admin/get-business-details`, { params: params })
            .then((response) => {
                return response;
            })
    },

    getDailyCouponsIssued: (params) => {
        return apiServices.get(`/admin/get-daily-coupons`, { params: params })
            .then((response) => {
                return response;
            })
    },

    getDailyCouponCounts: (params) => {
        return apiServices.get(`/admin/get-total-coupons`, { params: params })
            .then((response) => {
                return response;
            })
    },

    /**
    * function get invoice list
    * @param params
    * @return view
    * @author Jasin
    * @created_at 28/12/2021
    */
    getBrandListSelect: () => {
        return apiServices.get(`/admin/get-brand-list-select`)
            .then((response) => {
                return response;
            })
    },
}
