import { apiServices, cookieServices } from '../../_helpers';


export const RegisterServices = {

    /**
     * Service for register brand
     * @param {Object} params - The params which are used for registration api.
     * @param {string} params.first_name - First name of the brand user
     * @param {string} params.last_name - Second name of the brand user
     * @param {string} params.email - Email of the brand user
     * @param {string} params.password - Password entered by the brand user
     * @param {string} params.confirm_password - Confirm password entered by the brand user
     * @author Akshay 
     */
    register: (params) => {
        return apiServices.post('/register', params)
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('accessToken', response.data.token, options);
                return response;
            })
    }

};
